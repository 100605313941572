import { takeLatest, put, call } from 'redux-saga/effects';
import * as CartActions from './actions';
import * as CartService from '../../../api/CartService';
import { CartItem, MealTypeIndex } from '../../../models/CartItem';
import i18n from '../../../i18n/i18n';

const calculatePrice = (meal: CartItem) => {
  const {
    breakfast,
    lunch,
    dinner,
    noOfDays,
    goalId: { price_breakfast, price_dinner, price_lunch },
  } = meal;

  const processedNoDays = noOfDays.toString(10) as MealTypeIndex;

  let price = 0;

  if (breakfast) price += price_breakfast[processedNoDays];
  if (lunch) price += price_lunch[processedNoDays];
  if (dinner) price += price_dinner[processedNoDays];

  return price;
};

export function* handleFetchCart() {
  try {
    const response = yield call(CartService.getCart);
    const {
      data: { cart },
    } = response;

    const cartItems = cart.items.map((item: CartItem) => ({
      ...item,
      _price: calculatePrice(item),
    }));

    yield put(
      CartActions.fetchCartSuccess({
        ...cart,
        items: cartItems,
      }),
    );
  } catch (error) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error;
    yield put(
      CartActions.fetchCartFail(
        message[i18n.getLang()] || 'Error occurred fetching cart',
      ),
    );
  }
}

export function* watchFetchCart() {
  yield takeLatest(CartActions.FETCH_CART, handleFetchCart);
}
