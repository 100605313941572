/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { RootState } from 'MyTypes';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Layout from '../../layouts/Layout';
import { Steps } from '../../components';
import './Menu.scss';
import SpecialDetailsPopup from './components/SpecialDetailsPopup/SpecialDetailsPopup';
import PlanDetailsPopup from './components/PlanDetailsPopup/PlanDetailsPopup';
import FACheckboxButton from './components/FACheckboxButton/FACheckboxButton';
import MealPlanDetailFooter from './components/MealPlanDetailFooter/MealPlanDetailFooter';
import {
  fetchCuisines,
  fetchCuisineById,
  fetchGoalAndMeals,
  fetchExtra,
  fetchStartDates,
  fetchHolidays,
} from '../../api';
import * as CartService from '../../api/CartService';
import { Goal } from '../../models';
import { selectToken } from '../../store/auth/duck/selectors';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

interface Props {
  token: string | null;
}
type TotalAvgMacro = {
  total_calories: number;
  total_protein: number;
  total_carb: number;
  total_fat: number;
};

const days = '10';
const Menu = ({ token }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  // Goal Info
  const [goalId, setGoalId] = useState<string | null>(null);
  const [goal, setGoal] = useState<Goal | null>(null);
  // Middle Control
  const [selectedDays, setSelectedDays] = useState<string>(days);
  const [numPeople, setNumPeople] = useState<number>(1);
  // Prices
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [perDayPrice, setPerDayPrice] = useState<number>(0);

  // Meal Type selecttion (Keep for Menu to show snacks)
  const [breakfastSelected, setBreakfastSelected] = useState<boolean>(true);
  const [lunchSelected, setLunchSelected] = useState<boolean>(true);
  const [dinnerSelected, setDinnerSelected] = useState<boolean>(true);
  const [selectedMealTypeNum, setSelectedMealTypeNum] = useState<number>(3);
  // Start Date Options
  const [startDateOpts, setStartDateOtps] = useState<Array<object>>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [earliestDateInPlan, setEarliestDateInPlan] = useState<string>('');
  // Week Options
  const [weekOpts, setWeekOpts] = useState<Array<object>>([]);
  const [weekOpt, setWeekOpt] = useState<string | null>(null);
  // Holidays
  const [holidays, setHolidays] = useState<Array<object>>([]);
  const [holidayList, setHolidayList] = useState<Array<string>>([]);
  // Meal List
  const [allMealList, setAllMealList] = useState<Array<object> | null>([]);
  const [allMealDatesForCheckout, setAllMealDatesForCheckout] = useState<
    Array<string>
  >([]);
  const [allMealForCheckout, setAllMealForCheckout] = useState<any>([]);
  const [mealList, setMealList] = useState<Array<object> | null>([]);
  const [gotMealList, setGotMealList] = useState<boolean>(false);
  // End Days
  const [endDate, setEndDate] = useState<string>('');
  // Status, Waiting Checkout
  const [waitingCheckout, setWaitingCheckout] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Macro
  const [totalMacro, setTotalMacro] = useState<TotalAvgMacro>({
    total_calories: 0,
    total_protein: 0,
    total_carb: 0,
    total_fat: 0,
  });
  // Skip Days
  const [skipDays, setSkipDays] = useState<Array<string>>([]);

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Checkout
  // const [checkout, setCheckout] = useState<boolean>(false);

  const dateFormat = 'DD-MM-YYYY';
  const labelFormat = 'MMMM, D, YYYY (ddd)';

  // For Menu
  const [brandOpts, setBrandOpts] = useState<any>(null);
  const [goalOpts, setGoalOpts] = useState<any>(null);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [selectedGoal, setSelectedGoal] = useState<any>(null);

  const updateBrand = (e: any) => {
    if (!brandOpts) return;
    let thisVal = '';
    if (e.value) {
      thisVal = e.value;
    } else {
      thisVal = e.value;
    }
    const thisLabel = brandOpts
      .filter((el: any) => el.value === thisVal)
      .map((el: any) => el.label);
    setSelectedBrand({
      value: thisVal,
      label: thisLabel,
    });
  };
  const updateGoal = (e: any) => {
    if (!goalOpts) return;
    let thisVal = '';
    if (e.value) {
      thisVal = e.value;
    } else {
      thisVal = e.value;
    }
    const thisLabel = goalOpts
      .filter((el: any) => el.value === thisVal)
      .map((el: any) => el.label);
    setSelectedGoal({
      value: thisVal,
      label: thisLabel,
    });
  };
  // Get Goal Id (Translate Cuisine Name + Goal Name to Goal Id)
  useEffect(() => {
    setLoading(true);
    fetchCuisines()
      .then((res) => {
        const cuisines = res;
        // console.log('cuisines', cuisines);
        const thisBrandOpts: any = [];
        cuisines.forEach((index: any) => {
          thisBrandOpts.push({
            value: index._id,
            label: index.name,
          });
        });
        if (thisBrandOpts.length > 0) {
          setBrandOpts(thisBrandOpts);
          setSelectedBrand(thisBrandOpts[0]);
        } else {
          window.alert('No Brand data');
        }
      })
      .catch((error) => {
        window.alert('Load Brand Failed');
        // history.push('/');
      });
  }, []);

  useEffect(() => {
    if (!selectedBrand) return;
    setLoading(true);
    fetchCuisineById(selectedBrand.value)
      .then((res) => {
        const filteredGoals = res.data.result.filter(
          (g: any) => g._id != '5eb2a44780f80f42d7806fd0',
        );
        const thisGoals = filteredGoals;
        setLoading(false);
        const thisGoalOpts: any = [];
        thisGoals.forEach((index: any) => {
          thisGoalOpts.push({
            value: index._id,
            label: index.name,
          });
        });
        if (thisGoalOpts.length > 0) {
          setGoalOpts(thisGoalOpts);
          setSelectedGoal(thisGoalOpts[0]);
        } else {
          // window.alert('No Goal(s) for this Brand');
          setGoalOpts(null);
          setSelectedGoal(null);
        }
      })
      .catch((error) => {
        window.alert('Load Goals Failed');
      });
  }, [selectedBrand]);

  useEffect(() => {
    if (!selectedGoal) return;
    setGoalId(selectedGoal.value);
    // setLoading(true);
  }, [selectedGoal]);

  // Get Goal Info
  useEffect(() => {
    if (!goalId) {
      setLoading(false);
      return;
    }
    const today = moment();
    const firstDate = moment().startOf('isoWeek');
    const todayWeekday = today.day();
    const todayTime = today.get('hour');
    if (
      todayWeekday < 1 ||
      todayWeekday > 6 ||
      (todayWeekday === 6 && todayTime > 9)
    ) {
      firstDate.add(7, 'days');
    }
    let paramWeekOpt = null;
    if (weekOpt) {
      paramWeekOpt = weekOpt.replace(',', '&end_date=');
    } else {
      paramWeekOpt =
        `${firstDate.format(dateFormat)},${firstDate.add(6, 'days').format(dateFormat)}`.replace(
          ',',
          '&end_date=',
        );
    }
    fetchGoalAndMeals(goalId, paramWeekOpt).then((res) => {
      setGoal(res.data.goal);
      setMealList(res.data.meals);
      setLoading(false);
    });
    // if (!gotMealList) {
    // 	fetchGoalAndMeals(goalId, null)
    // 		.then(((res) => {
    // 			setGotMealList(true);
    // 			const allMealListResult = res.data.meals;
    // 			setAllMealList(allMealListResult);
    // 			setLoading(false);
    // 			// console.log(allMealListResult);
    // 			if (allMealListResult && allMealListResult.length > 0) {
    // 				// const allMealListArr = allMealListResult.map((a: any) => a._id);
    // 			}
    // 		}));
    // }
  }, [goalId, gotMealList, weekOpt]);

  // Set Holidays
  useEffect(() => {
    fetchHolidays().then((res: any) => {
      if (res.data.holidays) {
        const holidaysRaw = res.data.holidays;
        const holidayInfo = holidaysRaw.filter((el: any) => !el.isDeleted);
        const thisHolidayList: any = [];
        holidayInfo.forEach((entry: any) => {
          thisHolidayList.push(entry.date);
        });
        setHolidays(holidayInfo);
        setHolidayList(thisHolidayList);
        // console.log('res.data.holidays', res.data.holidays);
      }
    });
  }, []);

  // Set Week Options
  useEffect(() => {
    const today = moment();
    const firstDate = moment().startOf('isoWeek');
    const todayWeekday = today.day();
    const todayTime = today.get('hour');
    if (
      todayWeekday < 1 ||
      todayWeekday > 6 ||
      (todayWeekday === 6 && todayTime > 9)
    ) {
      firstDate.add(7, 'days');
    }
    setWeekOpt(
      `${firstDate.format(dateFormat)},${firstDate.add(6, 'days').format(dateFormat)}`,
    );
    const weekLabelFormat = 'D MMM';
    const weekOpt1 = {
      id: 'weekOpt1',
      period: `${firstDate.subtract(6, 'days').format(dateFormat)},${firstDate.add(4, 'days').format(dateFormat)}`,
      label: `${firstDate.subtract(4, 'days').format(weekLabelFormat)} - ${firstDate.add(4, 'days').format(weekLabelFormat)}`,
      // selected: true
    };
    const weekOpt2 = {
      id: 'weekOpt2',
      period: `${firstDate.add(3, 'days').format(dateFormat)},${firstDate.add(4, 'days').format(dateFormat)}`,
      label: `${firstDate.subtract(4, 'days').format(weekLabelFormat)} - ${firstDate.add(4, 'days').format(weekLabelFormat)}`,
      // selected: true
    };
    const weekOpt3 = {
      id: 'weekOpt3',
      period: `${firstDate.add(3, 'days').format(dateFormat)},${firstDate.add(4, 'days').format(dateFormat)}`,
      label: `${firstDate.subtract(4, 'days').format(weekLabelFormat)} - ${firstDate.add(4, 'days').format(weekLabelFormat)}`,
      // selected: true
    };
    setWeekOpts([weekOpt1, weekOpt2, weekOpt3]);
    setWeekOpt(weekOpt1.period);
  }, []);

  // Update Weekly Menu

  // For popup
  const [popupExclusions, setPopupExclusions] = useState<any>([]);

  useEffect(() => {
    fetchExtra().then((res) => {
      // console.log(res.data.extras);
      setPopupExclusions(res.data.extras);
    });
  }, []);

  const popupProteinChange: any = [
    t('mealPlan.noChange'),
    t('mealPlan.noBeef'),
    t('mealPlan.noFish'),
    t('mealPlan.onlyFish'),
    t('mealPlan.noPork'),
    t('mealPlan.noSalmon'),
    t('mealPlan.noTuna'),
  ];

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [popupExtraCharge, setPopupExtraCharge] = useState<number>(0);
  const [popupExtraChargeEach, setPopupExtraChargeEach] = useState<number>(0);
  const [breakfastDiscount, setBreakfastDiscount] = useState<number>(0);

  const [selectedExclusions, setSelectedExclusions] = useState<any[] | null>(
    [],
  );

  const [selectedProteinChange, setSelectedProteinChange] = useState<
    any | null
  >(popupProteinChange[0]);

  const onPopupExclusionChange = (newExclusion: any) => {
    const newExclusionVal = newExclusion._id;
    const newExclusionPrice = newExclusion.amount;
    let newPopupExtraChargeEach = popupExtraChargeEach;
    if (selectedExclusions && selectedExclusions.includes(newExclusionVal)) {
      // Already checked
      const newExclusions = selectedExclusions.filter(
        (exclusion) => exclusion !== newExclusionVal,
      );
      setSelectedExclusions(newExclusions);
      newPopupExtraChargeEach -= newExclusionPrice;
    } else if (selectedExclusions) {
      // Not checked
      const newExclusionArr = [...selectedExclusions, newExclusionVal];
      setSelectedExclusions(newExclusionArr);
      newPopupExtraChargeEach += newExclusionPrice;
    } else {
      // First check
      setSelectedExclusions([newExclusionVal]);
      newPopupExtraChargeEach += newExclusionPrice;
    }
    setPopupExtraChargeEach(newPopupExtraChargeEach);
  };

  // Calculate Extra Price for special request
  useEffect(() => {
    setPopupExtraCharge(
      parseInt(selectedDays, 10) * (popupExtraChargeEach + breakfastDiscount),
    );
  }, [selectedDays, popupExtraChargeEach, breakfastDiscount]);

  const onPopupProteinChange = (newProtein: any) => {
    setSelectedProteinChange(newProtein);
  };

  const onPopupConfirm = () => {
    setShowPopup(false);
  };

  // Calculate price per day
  useEffect(() => {
    const newPrice = Math.ceil(
      totalPrice / (parseInt(selectedDays, 10) * numPeople),
    );
    setPerDayPrice(newPrice);
  }, [numPeople, selectedDays, totalPrice]);

  const updateWeek = (tarWeek: any) => {
    setWeekOpt(tarWeek);
  };

  // Handle the skip days
  const updateSkipDays = (date: string) => {
    // const newSkipDays = skipDays;
    console.log('updateSkipDays', date);
    if (skipDays.includes(date)) {
      // Already exist
      const newSkipDays = skipDays.filter((exclusion) => exclusion !== date);
      setSkipDays(newSkipDays);
    } else {
      // Add new
      const newSkipDays = [...skipDays, date];
      setSkipDays(newSkipDays);
    }
  };

  return (
    <Layout>
      <h1 className="a11y-hidden">Our Healthy Meal Plan</h1>
      <Helmet>
        <title>
          Healthy Meal Plan Delivery Menu |Keto & Low carb Diet Meal Plan| NOSH
        </title>
        <meta
          name="description"
          content="Looking for an easy healthy meal plan? NOSH provides you with a diverse, delicious menu for your daily meals. Order your ready-made, nutritious food from us today."
        />
      </Helmet>
      <main className="steps-container relative static-menu">
        <div className="static-menu-inner">
          {loading ? <LoadingScreen /> : ''}
          <section className="our-menu-header">
            <div className="content">
              <h2 className="our-menu-title">{t('menu.title')}</h2>
            </div>
          </section>
          <div className="static-menu-content">
            <form className="step3-container">
              <div className="right-container">
                <p className="our-menu-intro">{t('menu.desc')}</p>
                <div className="meal-options-container">
                  {brandOpts && selectedBrand ? (
                    <div className="meal-options-item">
                      <div className="step3-label-text">{t('menu.step1')}</div>
                      <div className="meal-options-choice-container">
                        <Select
                          className="custom-select"
                          options={brandOpts}
                          value={selectedBrand}
                          onChange={(e) => updateBrand(e)}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {goalOpts && selectedGoal ? (
                    <div className="meal-options-item">
                      <div className="step3-label-text">{t('menu.step2')}</div>
                      <div className="meal-options-choice-container">
                        <Select
                          className="custom-select"
                          options={goalOpts}
                          value={selectedGoal}
                          onChange={(e) => updateGoal(e)}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* <div className="meal-table-tophead">
							{t('mealPlan.weeklyMenu')}
						</div>
						<p className="meal-table-mob-hints">{t('mealPlan.mealTableMobHints')}</p> */}
                {selectedGoal ? (
                  <div className="week-pickers radio-pickers">
                    <div className="step3-label-text">{t('menu.step3')}</div>
                    <div className="week-pickers radio-pickers-row">
                      {weekOpts &&
                        weekOpts.map((dateVal: any, index: number) => {
                          const output = [];
                          output.push(
                            <div
                              key={`weeks-${dateVal.id}`}
                              className="week-picker radio-picker"
                            >
                              <input
                                id={`weeks-${index}`}
                                type="radio"
                                name="weeks"
                                value={dateVal.period}
                                checked={weekOpt === dateVal.period}
                                onChange={() => updateWeek(dateVal.period)}
                              />
                              <label
                                className="radio-picker-label"
                                htmlFor={`weeks-${index}`}
                              >
                                <span className="radio-picker-txt">
                                  {dateVal.label}
                                </span>
                              </label>
                            </div>,
                          );
                          return output;
                        })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </form>
            {mealList && mealList.length > 0 && selectedGoal ? (
              <MealPlanDetailFooter
                goal={goal}
                mealList={mealList}
                holidays={holidays}
                skipDays={skipDays}
                hasSnack={
                  goal ? goal.hasSnack && selectedMealTypeNum > 2 : false
                }
                hasExtraMeal={goal ? goal.hasExtraMeal : false}
                hasExtraLunch={
                  goal ? goal.hasExtraLunch && lunchSelected : false
                }
                hasExtraDinner={
                  goal ? goal.hasExtraDinner && dinnerSelected : false
                }
                allMealDatesForCheckout={allMealDatesForCheckout}
                updateSkipDays={updateSkipDays}
              />
            ) : selectedGoal ? (
              <div className="meal-table-noresult">
                {t('mealPlan.noWeeklyMenuForThisWeek')}
              </div>
            ) : (
              <div className="meal-table-noresult">
                No goal(s) for this brand. Please select another brand.
              </div>
            )}
            <div className="step3-container">
              <div className="right-container">
                <div className="right-bottom-group">
                  <div />
                  <div className="checkout-container">
                    <Link to="/MealPlans/1">
                      <button
                        type="button"
                        className={selectedMealTypeNum > 1 ? '' : 'is-disabled'}
                      >
                        {t('menu.orderNow')}
                      </button>
                    </Link>
                  </div>
                  <div />
                </div>
              </div>
            </div>
            <SpecialDetailsPopup
              visible={showPopup}
              selectedExclusions={selectedExclusions}
              exclusions={popupExclusions}
              onExclusionsChange={onPopupExclusionChange}
              // selectedProteinChange={selectedProteinChange}
              // proteinChanges={popupProteinChange}
              // onProteinChange={onPopupProteinChange}
              extraCharge={popupExtraCharge}
              onConfirm={onPopupConfirm}
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  const {
    match: {
      params: { brand, goal, days },
    },
  } = props;
  return {
    token: selectToken(state),
  };
};

export default connect(mapStateToProps)(Menu);
