import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import './FACheckboxButton.scss';

type Props = {
  onClick: () => void;
  isSelected: boolean;
  label: string;
  showThreeMeals: {
    canSelect: boolean | null;
    selected: boolean | null;
    show: boolean | null;
  } | null;
};

const FACheckboxButton = ({
  onClick,
  label,
  isSelected,
  showThreeMeals,
}: Props) => {
  return (
    <div>
      {showThreeMeals != null && showThreeMeals.canSelect == true ? (
        <button
          type="button"
          className="checkbox-button-container"
          onClick={onClick}
        >
          <FontAwesomeIcon
            icon={isSelected ? faCheckSquare : faSquare}
            size="2x"
            className="checkbox-icon"
          />
          <div className="checkbox-label">{label}</div>
        </button>
      ) : (
        <button
          type="button"
          className="checkbox-button-container"
          onClick={onClick}
          disabled
        >
          <FontAwesomeIcon
            icon={isSelected ? faCheckSquare : faSquare}
            size="2x"
            className="checkbox-icon-disabled"
          />
          <div className="checkbox-label">{label}</div>
        </button>
      )}
    </div>
  );
};

export default FACheckboxButton;
