import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { useTranslation } from 'react-i18next';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { Redirect } from 'react-router-dom';
// import Select from 'react-select';
// import moment from 'moment';
import { Link } from 'react-router-dom';
import { selectToken } from '../../store/auth/duck/selectors';
import Layout from '../../layouts/Layout';
import { fetchUserDetail } from '../../api';
import './CheckoutSuccess.scss';
import PartnersLogos from './components/PartnersLogos/PartnersLogos';
import FirstOrderPopup from './components/FirstOrderPopup/FirstOrderPopup';

type Props = {
  token: string | null;
};

const CheckoutSuccess = ({ token }: Props) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>({});
  const [showFirstOrderPopup, setShowFirstOrderPopup] =
    useState<boolean>(false);

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
    const queries = new URLSearchParams(window.location.search);
    const firstOrder = queries.get('firstOrder');
    if (firstOrder) {
      setShowFirstOrderPopup(true);
    }
  }, []);

  // Initialize User
  useEffect(() => {
    // const initUserDetail = () => {
    fetchUserDetail().then((res) => {
      const dataUser = res.data.user;
      setUser(dataUser);
    });
  }, []);

  return (
    <Layout>
      <FirstOrderPopup
        visible={showFirstOrderPopup && !!token}
        onClosePopup={() => {
          setShowFirstOrderPopup(false);
        }}
      />
      <div className="checkout-success">
        <h1 className="checkout-success-title">{t('checkoutSuccess.title')}</h1>
        <div className="checkout-success-subtitle">
          {t('checkoutSuccess.desc')}
        </div>
        <div className="checkout-success-separator" />

        {user?.isVIP ? (
          <>
            <div className="checkout-success-desc">
              {t('checkoutSuccess.thanksAgain')}
            </div>
            <div className="checkout-vip">
              <div className="checkout-vip-logo" />
              <div className="checkout-vip-msg">
                {t('checkoutSuccess.vipGetInTouch')}
                <br />
                <strong>
                  {t('checkoutSuccess.vipTel')} {t('checkoutSuccess.vipEmail')}
                </strong>
                <br />
                <br />
                {t('checkoutSuccess.vipNotes')}
              </div>
            </div>
          </>
        ) : (
          <div className="checkout-success-desc">
            {t('checkoutSuccess.enjoy')}
          </div>
        )}
        <div className="checkout-success-desc">
          {t('checkoutSuccess.referralCode')}
          {': '}
          <strong>{user.referralCode}</strong>
        </div>
        <div className="checkout-success-desc">
          {t('checkoutSuccess.referralMsg')}
        </div>
        <PartnersLogos page="checkout_success" />
        <Link type="button" className="checkout-home-btn" to="/">
          {t('checkoutSuccess.backToHome')}
        </Link>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectToken(state),
});

export default connect(mapStateToProps)(CheckoutSuccess);
