declare global {
  interface Window {
    ga: any;
    gtag: any;
    resetPageTitle: any;
  }
}

export const setUserGa = (userId: string) => {
  if (window.ga) {
    console.log('setUserGa', userId);
    window.ga('set', 'userId', userId);
  }
};

export const setUserGtag = (userId: string) => {
  if (window.gtag) {
    console.log('setUserGtag', userId);
    window.gtag('set', 'userId', userId);
  }
};

export const setUserForGoogleTracking = (userId: string) => {
  setTimeout(() => {
    setUserGa(userId);
    setUserGtag(userId);
  }, 500);
};

export default setUserForGoogleTracking;
