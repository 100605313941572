/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { RootState } from 'MyTypes';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import moment from 'moment';
import { setTimeout } from 'timers';
import Layout from '../../layouts/Layout';
import { Steps } from '../../components';
import './Preview.scss';
import SpecialDetailsPopup from './components/SpecialDetailsPopup/SpecialDetailsPopup';
import PlanDetailsPopup from './components/PlanDetailsPopup/PlanDetailsPopup';
import FACheckboxButton from './components/FACheckboxButton/FACheckboxButton';
import MealPlanDetailFooter from './components/MealPlanDetailFooter/MealPlanDetailFooter';
import {
  fetchCuisines,
  fetchCuisineById,
  fetchGoalAndMeals,
  fetchExtra,
  fetchStartDates,
  fetchHolidays,
  submitAddOns,
} from '../../api';
import * as CartService from '../../api/CartService';
import { Goal } from '../../models';
import { selectUserCart } from '../../store/cart/duck/selectors';
import { selectToken } from '../../store/auth/duck/selectors';
import { UserCart } from '../../models/CartItem';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

import AddOnPopup from './components/AddOnPopup/AddOnPopup';

interface Props {
  token: string | null;
  userCart: UserCart | null;
  brandName: string;
  goalName: string;
  days: string;
}
type TotalAvgMacro = {
  total_calories: number;
  total_protein: number;
  total_carb: number;
  total_fat: number;
};

const Preview = ({ token, userCart, brandName, goalName, days }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  // Goal Info
  const [goalId, setGoalId] = useState<string | null>(null);
  const [goal, setGoal] = useState<Goal | null>(null);
  // Middle Control
  const [selectedDays, setSelectedDays] = useState<string>(days);
  const [numPeople, setNumPeople] = useState<number>(1);
  // Prices
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [perDayPrice, setPerDayPrice] = useState<number>(0);
  const [consultationFee, setConsultationFee] = useState<number>(0);
  const [consultationSession, setConsultationSession] = useState<number>(0);
  // Meal Type selecttion
  const [breakfastSelected, setBreakfastSelected] = useState<boolean>(true);
  const [lunchSelected, setLunchSelected] = useState<boolean>(true);
  const [dinnerSelected, setDinnerSelected] = useState<boolean>(true);
  const [selectedMealTypeNum, setSelectedMealTypeNum] = useState<number>(3);
  // Start Date Options
  const [startDateOpts, setStartDateOtps] = useState<Array<object>>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [earliestDateInPlan, setEarliestDateInPlan] = useState<string>('');
  // Week Options
  const [weekOpts, setWeekOpts] = useState<Array<object>>([]);
  const [weekOpt, setWeekOpt] = useState<string | null>(null);
  // Holidays
  const [holidays, setHolidays] = useState<Array<object>>([]);
  const [holidayList, setHolidayList] = useState<Array<string>>([]);
  // Meal List
  const [allMealList, setAllMealList] = useState<Array<object> | null>(null);
  const [allMealDatesForCheckout, setAllMealDatesForCheckout] = useState<
    Array<string>
  >([]);
  const [allMealForCheckout, setAllMealForCheckout] = useState<any>([]);
  const [mealList, setMealList] = useState<Array<object> | null>([]);
  const [gotMealList, setGotMealList] = useState<boolean>(false);
  // End Days
  const [endDate, setEndDate] = useState<string>('');
  // Status, Waiting Checkout
  const [waitingCheckout, setWaitingCheckout] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Macro
  const [totalMacro, setTotalMacro] = useState<TotalAvgMacro>({
    total_calories: 0,
    total_protein: 0,
    total_carb: 0,
    total_fat: 0,
  });
  // Skip Days
  const [skipDays, setSkipDays] = useState<Array<string>>([]);

  // Add On Popup
  const [addOnsToAdd, setAddOnsToAdd] = useState<any>(null);
  const [waitingAddOn, setWaitingAddOn] = useState<boolean>(false);
  const [AddOnPopupVisible, setAddOnPopupVisible] = useState<boolean>(false);
  const [AddOnPopupMode, setAddOnPopupMode] = useState<string>('');

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Checkout
  // const [checkout, setCheckout] = useState<boolean>(false);

  const dateFormat = 'DD-MM-YYYY';
  const labelFormat = 'MMMM, D, YYYY (ddd)';

  // Get Goal Id (Translate Cuisine Name + Goal Name to Goal Id)
  useEffect(() => {
    setLoading(true);
    fetchCuisines()
      .then((res) => {
        const cuisines = res;
        const targetCuisine = cuisines.filter(
          (el: any) => el.name.replace(/[^a-zA-Z0-9-_]/g, '') === brandName,
        );
        if (targetCuisine.length < 1) {
          window.alert('Brand not found');
          history.push('/MealPlans/1');
        } else {
          const targetCuisineId = targetCuisine[0]._id;
          fetchCuisineById(targetCuisineId)
            .then((res) => {
              const goalList = res.data.result;
              const targetGoal = goalList.filter(
                (el: any) =>
                  el.name.replace(/[^a-zA-Z0-9-_]/g, '') === goalName,
              );
              if (targetGoal.length < 1) {
                window.alert('Goal not found');
                history.push('/MealPlans/1');
              } else {
                const targetGoalId = targetGoal[0]._id;
                setGoalId(targetGoalId);
              }
            })
            .catch((error) => {
              window.alert(error);
              history.push('/MealPlans/1');
            });
        }
      })
      .catch((error) => {
        window.alert(error);
        history.push('/MealPlans/1');
      });
  }, [brandName, goalName]);

  // Get Goal Info
  useEffect(() => {
    if (!goalId) {
      return;
    }
    const today = moment().utcOffset(8);
    const firstDate = moment().utcOffset(8).startOf('isoWeek');
    const todayWeekday = today.day();
    const todayTime = today.get('hour');
    if (
      todayWeekday < 1 ||
      todayWeekday > 6 ||
      (todayWeekday === 6 && todayTime > 9)
    ) {
      firstDate.add(7, 'days');
    }
    let paramWeekOpt = null;
    if (weekOpt) {
      paramWeekOpt = weekOpt.replace(',', '&end_date=');
    } else {
      paramWeekOpt = `${firstDate.format(dateFormat)},${firstDate
        .add(6, 'days')
        .format(dateFormat)}`.replace(',', '&end_date=');
    }
    const allWeekOpts = `${firstDate.format(dateFormat)},${firstDate
      .add(3, 'month')
      .format(dateFormat)}`.replace(',', '&end_date='); // For getting all meal list
    fetchGoalAndMeals(goalId, paramWeekOpt).then((res) => {
      setGoal(res.data.goal);
      setBreakfastSelected(
        goal ? goal?.showThreeMeals?.breakfast?.selected : false,
      );
      setLunchSelected(goal ? goal?.showThreeMeals?.lunch?.selected : false);
      setDinnerSelected(goal ? goal?.showThreeMeals?.dinner?.selected : false);
      setMealList(res.data.meals);
      if (!gotMealList) {
        fetchGoalAndMeals(goalId, allWeekOpts)
          .then((res) => {
            setGotMealList(true);
            const allMealListResult = res.data.meals;
            setAllMealList(allMealListResult);
            setLoading(false);
          })
          .catch((error) => {
            window.alert('Error initializing meal list');
            history.push('/MealPlans/1');
          });
      } else {
        setLoading(false);
      }
    });
  }, [goalId, gotMealList, weekOpt]);

  // Set Start Date
  useEffect(() => {
    fetchStartDates().then((res) => {
      const startDatesArr: any = [];
      const startDatesRawArr = res.data.available_times;
      // console.log('fetchStartDates', startDatesRawArr);
      startDatesRawArr.forEach((val: any, key: string) => {
        const startDateObj = {
          id: `week1Opt${key}`,
          value: moment(val, moment.ISO_8601, true)
            .utcOffset(8)
            .format(dateFormat),
          label: moment(val, moment.ISO_8601, true)
            .utcOffset(8)
            .format(labelFormat),
          isDisabled: false,
        };
        startDatesArr.push(startDateObj);
      });
      // console.log(startDatesArr);
      setStartDateOtps(startDatesArr);

      if (startDatesRawArr && startDatesRawArr.length) {
        const defaultStartDates = startDatesRawArr[0];
        setStartDate(
          moment(defaultStartDates, moment.ISO_8601)
            .utcOffset(8)
            .format(dateFormat),
        );
      }
    });
  }, []);

  // // Console Start Date
  // useEffect(() => {
  // 	console.log(startDate);
  // }, [startDate]);

  // Set Holidays
  useEffect(() => {
    fetchHolidays().then((res: any) => {
      if (res.data.holidays) {
        const holidaysRaw = res.data.holidays;
        const holidayInfo = holidaysRaw.filter((el: any) => !el.isDeleted);
        const thisHolidayList: any = [];
        holidayInfo.forEach((entry: any) => {
          thisHolidayList.push(entry.date);
        });
        setHolidays(holidayInfo);
        setHolidayList(thisHolidayList);
        // console.log('res.data.holidays', res.data.holidays);
      }
    });
  }, []);

  // Set Week Options
  useEffect(() => {
    const today = moment().utcOffset(8);
    const firstDate = moment().utcOffset(8).startOf('isoWeek');
    const todayWeekday = today.day();
    const todayTime = today.get('hour');
    if (
      todayWeekday < 1 ||
      todayWeekday > 6 ||
      (todayWeekday === 6 && todayTime > 9)
    ) {
      firstDate.add(7, 'days');
    }
    setWeekOpt(
      `${firstDate.format(dateFormat)},${firstDate
        .add(6, 'days')
        .format(dateFormat)}`,
    );
    const weekLabelFormat = 'D MMM';
    const weekOpt1 = {
      id: 'weekOpt1',
      period: `${firstDate.subtract(6, 'days').format(dateFormat)},${firstDate
        .add(4, 'days')
        .format(dateFormat)}`,
      label: `${firstDate
        .subtract(4, 'days')
        .format(weekLabelFormat)} - ${firstDate
        .add(4, 'days')
        .format(weekLabelFormat)}`,
      // selected: true
    };
    const weekOpt2 = {
      id: 'weekOpt2',
      period: `${firstDate.add(3, 'days').format(dateFormat)},${firstDate
        .add(4, 'days')
        .format(dateFormat)}`,
      label: `${firstDate
        .subtract(4, 'days')
        .format(weekLabelFormat)} - ${firstDate
        .add(4, 'days')
        .format(weekLabelFormat)}`,
      // selected: true
    };
    const weekOpt3 = {
      id: 'weekOpt3',
      period: `${firstDate.add(3, 'days').format(dateFormat)},${firstDate
        .add(4, 'days')
        .format(dateFormat)}`,
      label: `${firstDate
        .subtract(4, 'days')
        .format(weekLabelFormat)} - ${firstDate
        .add(4, 'days')
        .format(weekLabelFormat)}`,
      // selected: true
    };
    setWeekOpts([weekOpt1, weekOpt2, weekOpt3]);
    setWeekOpt(weekOpt1.period);
  }, []);

  // Update Weekly Menu

  // For popup
  const [popupExclusions, setPopupExclusions] = useState<any>([]);

  useEffect(() => {
    if (!goalId) return;
    fetchExtra().then((res) => {
      // console.log(res.data.extras);
      // console.log('extra goalId', goalId);
      const finalExtra = res.data.extras.filter((extra: any) =>
        extra.allowedGoals?.includes(goalId),
      );
      setPopupExclusions(finalExtra);
      // setPopupExclusions(res.data.extras);
    });
  }, [goalId]);

  const popupProteinChange: any = [
    t('mealPlan.noChange'),
    t('mealPlan.noBeef'),
    t('mealPlan.noFish'),
    t('mealPlan.onlyFish'),
    t('mealPlan.noPork'),
    t('mealPlan.noSalmon'),
    t('mealPlan.noTuna'),
  ];

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [popupExtraCharge, setPopupExtraCharge] = useState<number>(0);
  const [popupExtraChargeEach, setPopupExtraChargeEach] = useState<number>(0);
  const [breakfastDiscount, setBreakfastDiscount] = useState<number>(0);

  const [selectedExclusions, setSelectedExclusions] = useState<any[] | null>(
    [],
  );

  //   const [showMealPerDay, setShowMealPerDay] = useState<boolean>(true);
  //   const hideShowMeal = () => {
  //     setShowMealPerDay(false);
  //   };

  const [selectedProteinChange, setSelectedProteinChange] = useState<
    any | null
  >(popupProteinChange[0]);

  const onPopupExclusionChange = (newExclusion: any) => {
    const newExclusionVal = newExclusion._id;
    const newExclusionPrice = newExclusion.amount;
    let newPopupExtraChargeEach = popupExtraChargeEach;
    if (selectedExclusions && selectedExclusions.includes(newExclusionVal)) {
      // Already checked
      const newExclusions = selectedExclusions.filter(
        (exclusion) => exclusion !== newExclusionVal,
      );
      setSelectedExclusions(newExclusions);
      newPopupExtraChargeEach -= newExclusionPrice;
    } else if (selectedExclusions) {
      // Not checked
      const newExclusionArr = [...selectedExclusions, newExclusionVal];
      setSelectedExclusions(newExclusionArr);
      newPopupExtraChargeEach += newExclusionPrice;
    } else {
      // First check
      setSelectedExclusions([newExclusionVal]);
      newPopupExtraChargeEach += newExclusionPrice;
    }
    setPopupExtraChargeEach(newPopupExtraChargeEach);
  };

  // Validate Meal Type
  useEffect(() => {
    let checkCount = 0;
    if (breakfastSelected) {
      checkCount++;
    }
    if (lunchSelected) {
      checkCount++;
    }
    if (dinnerSelected) {
      checkCount++;
    }
    setSelectedMealTypeNum(checkCount);
    // Breakfast Discount if selected all lunch, snack, breakfast
    const breakfastExcl = popupExclusions.filter(
      (el: any) => el.name === '###breakfast_discount###',
    );
    if (breakfastExcl.length) {
      const newExclusionVal = breakfastExcl[0]._id;
      const newExclusionPrice = breakfastExcl[0].amount;
      let newBreakfastDiscount = breakfastDiscount;
      if (breakfastSelected && lunchSelected && dinnerSelected) {
        if (selectedExclusions) {
          // have excl
          setSelectedExclusions([...selectedExclusions, newExclusionVal]);
        } else {
          // Not have excl
          setSelectedExclusions([newExclusionVal]);
        }
        newBreakfastDiscount = newExclusionPrice;
      } else if (
        selectedExclusions &&
        selectedExclusions.includes(newExclusionVal)
      ) {
        // Already checked
        const newExclusions = selectedExclusions.filter(
          (exclusion) => exclusion !== newExclusionVal,
        );
        setSelectedExclusions(newExclusions);
        newBreakfastDiscount = 0;
      }
      setBreakfastDiscount(newBreakfastDiscount);
    }
  }, [popupExclusions, breakfastSelected, lunchSelected, dinnerSelected]);

  // Calculate Extra Price for special request
  useEffect(() => {
    setPopupExtraCharge(
      parseInt(selectedDays, 10) * (popupExtraChargeEach + breakfastDiscount),
    );
  }, [selectedDays, popupExtraChargeEach, breakfastDiscount]);

  const onPopupProteinChange = (newProtein: any) => {
    setSelectedProteinChange(newProtein);
  };

  const onPopupConfirm = () => {
    setShowPopup(false);
  };

  const onSpecialRequest = () => {
    window.scrollTo(0, 0);
    setShowPopup(true);
  };

  // Popup Plan Detail
  const [showPlanDetailsPopup, setShowPlanDetailsPopup] =
    useState<boolean>(false);

  const closePlanDetailsPopupConfirm = () => {
    setShowPlanDetailsPopup(false);
  };

  const openPlanDetailsPopup = () => setShowPlanDetailsPopup(true);

  // Calculate price per day
  useEffect(() => {
    let newPrice = Math.ceil(
      totalPrice / (parseInt(selectedDays, 10) * numPeople),
    );
    if (newPrice < 0) {
      newPrice = 0;
    }
    setPerDayPrice(newPrice);
  }, [numPeople, selectedDays, totalPrice]);

  const onIncreaseNumPeople = () => setNumPeople(numPeople + 1);

  const onDecreaseNumPeople = () =>
    setNumPeople(numPeople > 1 ? numPeople - 1 : 1);

  const onAddToCart = () => window.alert('onAddToCart');

  const onCheckout = () => {
    if (selectedMealTypeNum < 2) {
      return;
    }
    // window.alert('onCheckout');
    let hasAddOns = false;
    if (userCart && userCart.addOns) {
      Object.entries(userCart.addOns).forEach(([key, value]: any) => {
        if (value?.length) {
          hasAddOns = true;
        }
      });
    }

    // Extreme Event workaround to call the logic
    // TODO - rewrite the logic check using react
    const loggedIn = document.querySelector('.logged-in-user');
    const login = document.querySelector('.login');
    if (!loggedIn) {
      if (hasAddOns) {
        setWaitingCheckout(true); // Such that will redirect to checkout if logged in
      } else {
        setAddOnPopupMode('checkout');
        setWaitingAddOn(true);
      }
      simulateMouseClick(login);
    } else if (hasAddOns) {
      doCheckout();
    } else {
      setAddOnPopupMode('checkout');
      setAddOnPopupVisible(true);
    }
    window.gtag('event', 'gtm.formSubmit', {
      event_category: 'page-preview',
      event_label: 'confirm-meal-plan-button',
    });
  };
  const openAddOn = () => {
    const loggedIn = document.querySelector('.logged-in-user');
    const login = document.querySelector('.login');
    setAddOnPopupMode('');
    if (!loggedIn) {
      setWaitingAddOn(true); // Such that will redirect to checkout if logged in
      simulateMouseClick(login);
    } else {
      setAddOnPopupVisible(true);
    }
  };

  const doCheckout = (directAddOns?: any) => {
    const thisMealList = allMealForCheckout;
    let goalHasSnack = false;
    if (goal) {
      goalHasSnack = goal.hasSnack;
    }
    let thisStartDate = earliestDateInPlan;
    if (!thisStartDate) {
      thisStartDate = startDate;
    }
    const data = {
      goalId,
      startDate: thisStartDate,
      endDate,
      quantity: numPeople,
      noOfDays: selectedDays,
      breakfast: breakfastSelected,
      lunch: lunchSelected,
      dinner: dinnerSelected,
      snacks: selectedMealTypeNum > 2 && goalHasSnack,
      meals: thisMealList,
      extras: selectedExclusions,
    };
    setLoading(true);
    CartService.addItem(data)
      .then(() => {
        if (!addOnsToAdd && !directAddOns) {
          history.push('/Checkout');
        } else {
          setTimeout(() => {
            submitAddOns(directAddOns || addOnsToAdd || {})
              .then((res: any) => {
                history.push('/Checkout');
              })
              .catch((err: any) => {
                // console.log(err.response);
                let errMSg = '';
                if (err.response) {
                  if (err.response.data) {
                    if (err.response.data.error) {
                      errMSg = err.response.data.error.message;
                    }
                  }
                }
                console.log(err);
                window.alert(`There is an error submitting add ons: ${errMSg}`);
              });
          }, 150);
        }
      })
      .catch((error) => {
        window.alert(t('error.errCartAdd'));
        // console.log(error);
      })
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (token) {
      // console.log('token', token);
      if (waitingCheckout) {
        setLoading(true);
        // console.log('waitingCheckout', waitingCheckout);
        setTimeout(() => {
          doCheckout();
          // const neverDisplayAddOn = localStorage.getItem('neverShowAddOn');
          // if (neverDisplayAddOn) {
          // 	doCheckout();
          // } else {
          // 	setLoading(false);
          // 	setAddOnPopupVisible(true);
          // }
        }, 250);
      }
      if (waitingAddOn) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setAddOnPopupVisible(true);
        }, 250);
      }
    }
  }, [token]);

  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  function simulateMouseClick(element: any) {
    mouseClickEvents.forEach((mouseEventType) =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      ),
    );
  }

  const onCheckboxBreakfast = () => setBreakfastSelected(!breakfastSelected);
  const onCheckboxLunch = () => setLunchSelected(!lunchSelected);
  const onCheckboxDinner = () => setDinnerSelected(!dinnerSelected);

  const updatePackage = (e: any) => {
    if (e.value) {
      setSelectedDays(e.value);
    } else {
      setSelectedDays(e);
    }
  };
  const updateStartDate = (tarDate: any) => {
    if (tarDate.value) {
      setStartDate(tarDate.value);
    } else {
      setStartDate(tarDate);
    }
  };
  const updateWeek = (tarWeek: any) => {
    setWeekOpt(tarWeek);
  };

  const countListAvgMacro = (updateMealList: any, daysNum: number) => {
    let total_calories = 0;
    let total_carb = 0;
    let total_protein = 0;
    let total_fat = 0;
    if (updateMealList) {
      if (updateMealList.length < 1) {
        return {
          total_calories,
          total_carb,
          total_protein,
          total_fat,
        };
      }
      updateMealList.forEach((item: any) => {
        if (item && item.dishId) {
          if (item.dishId.total_protein) {
            total_protein += item.dishId.total_protein;
          }
          if (item.dishId.total_fat) {
            total_fat += item.dishId.total_fat;
          }
          if (item.dishId.total_carb) {
            total_carb += item.dishId.total_carb;
          }
          if (item.dishId.total_calories) {
            total_calories += item.dishId.total_calories;
          }
        }
      });
      // const multiplier = 3;
      // const divider = updateMealList.length;
      const multiplier = 1;
      const divider = daysNum;
      total_calories /= divider;
      total_carb /= divider;
      total_protein /= divider;
      total_fat /= divider;
      total_calories *= multiplier;
      total_carb *= multiplier;
      total_protein *= multiplier;
      total_fat *= multiplier;
      total_calories = Math.ceil(total_calories);
      total_carb = Math.ceil(total_carb);
      total_protein = Math.ceil(total_protein);
      total_fat = Math.ceil(total_fat);
    }

    return {
      total_calories,
      total_carb,
      total_protein,
      total_fat,
    };
  };

  // Get HK ISO String by date
  const getISOStringByDate = (dateString: string) =>
    moment.utc(startDate, 'DD-MM-YYYY').subtract(8, 'hours').toISOString(true);

  // Set total Price and generate meal list for checkout
  useEffect(() => {
    let updatedtotalPrice = 0; // For Price Calucalation
    let updateMealList: any = []; // For storing updated meal list
    let updateMealListId: any = []; // For storing updated meal list in id string format

    // For storing the total avg macro figure (will change depending on the updated meal list)
    let thisTotalAvgMacro = {
      total_calories: 0,
      total_carb: 0,
      total_protein: 0,
      total_fat: 0,
    };

    // Create an adjusted meal list - sorted, excluded holidays and skipped days, within the package period
    // Later will use this list to filter out the updated meal list based on meal type options (breakfast, lunch, etc.)
    let adjustedAllMealList: any = [];
    let mealDaysListLen = parseInt(selectedDays, 10);
    if (allMealList) {
      // Exclude Holidays and Skip days
      adjustedAllMealList = [
        ...allMealList.filter(
          (el: any) =>
            !holidayList.includes(el.date) && !skipDays.includes(el.date),
        ),
      ];
    }

    // Generate list depending on package (days)
    const mealDateList: any = [];
    adjustedAllMealList.forEach((entry: any) => {
      if (!mealDateList.includes(entry.date)) {
        mealDateList.push(entry.date);
      }
    });
    // Sort the date from earlier to later
    mealDateList.sort((a: string, b: string) => {
      const da = new Date(a).getTime();
      const db = new Date(b).getTime();

      return da < db ? -1 : da > db ? 1 : 0;
    });

    // Handle the start date and end date
    if (startDate) {
      // Generate a list of dates who have meals for our package

      // Get start date index
      const formattedStartDate = getISOStringByDate(startDate);
      // Get closest date after start date (in case start date don't have meal)
      const tarDate = new Date(formattedStartDate).getTime();
      let closest = Infinity;
      mealDateList.forEach((d: any) => {
        const date = new Date(d).getTime();
        if (
          date >= tarDate &&
          (date <= new Date(closest).getTime() || date <= closest)
        ) {
          closest = d;
        }
      });
      const closestIndex = mealDateList.indexOf(closest);

      // Get end date index
      let endIndex = closestIndex + parseInt(selectedDays, 10);
      if (endIndex > mealDateList.length) {
        endIndex = mealDateList.length;
      }
      if (endIndex < 0) {
        endIndex = 0;
      }

      // Get the adjusted meal list for user package (within start date to end dates)
      const adjMealDateList = mealDateList.slice(closestIndex, endIndex);
      setAllMealDatesForCheckout(adjMealDateList);
      mealDaysListLen = adjMealDateList.length;
      // Filter our all All Meals List for our package
      adjustedAllMealList = [
        ...adjustedAllMealList.filter((el: any) =>
          adjMealDateList.includes(el.date),
        ),
      ];

      // Set start date and end date
      if (adjustedAllMealList.length) {
        const thisEndDate = Math.max.apply(
          null,
          adjustedAllMealList.map((e: any) => new Date(e.date)),
        );
        const thisStartDate = Math.min.apply(
          null,
          adjustedAllMealList.map((e: any) => new Date(e.date)),
        );
        setEndDate(moment(thisEndDate).utcOffset(8).format(dateFormat));
        setEarliestDateInPlan(
          moment(thisStartDate).utcOffset(8).format(dateFormat),
        );
      }
    }
    // console.log('adjustedAllMealList', adjustedAllMealList);

    // Filter out a updated meal list based on selected meal type (breakfast, lunch, dinner, etc.)
    // At the same time also calculate the total macro and total price
    if (goal) {
      if (breakfastSelected) {
        updatedtotalPrice +=
          goal.price_breakfast[parseInt(selectedDays, 10)] || 0;
        if (adjustedAllMealList) {
          const listForBreakfast = adjustedAllMealList.filter(
            (el: any) => el.mealType === 'breakfast',
          );
          updateMealList = [...updateMealList, ...listForBreakfast];
        }
      }
      if (lunchSelected) {
        updatedtotalPrice += goal.price_lunch[parseInt(selectedDays, 10)] || 0;
        if (adjustedAllMealList) {
          const listForLunch = adjustedAllMealList.filter(
            (el: any) => el.mealType === 'lunch',
          );
          updateMealList = [...updateMealList, ...listForLunch];
          // Extra List and Macro for Extra Lunch
          if (goal.hasExtraLunch) {
            const listForExtraLunch = adjustedAllMealList.filter(
              (el: any) => el.mealType === 'extra_lunch',
            );
            updateMealList = [...updateMealList, ...listForExtraLunch];
          }
        }
      }
      if (dinnerSelected) {
        updatedtotalPrice += goal.price_dinner[parseInt(selectedDays, 10)] || 0;
        if (adjustedAllMealList) {
          const listForDinner = adjustedAllMealList.filter(
            (el: any) => el.mealType === 'dinner',
          );
          updateMealList = [...updateMealList, ...listForDinner];
          // Extra List and Macro for Extra Lunch
          if (goal.hasExtraDinner) {
            const listForExtraDinner = adjustedAllMealList.filter(
              (el: any) => el.mealType === 'extra_dinner',
            );
            updateMealList = [...updateMealList, ...listForExtraDinner];
          }
        }
      }
      if (lunchSelected || dinnerSelected) {
        // Extra List and Macro for Extra Meal
        if (adjustedAllMealList && goal.hasExtraMeal) {
          const listForExtraMeal = adjustedAllMealList.filter(
            (el: any) => el.mealType === 'extra_meal',
          );
          updateMealList = [...updateMealList, ...listForExtraMeal];
        }
      }
      if (breakfastSelected && lunchSelected && dinnerSelected) {
        // Extra List and Macro for Snack
        if (adjustedAllMealList && goal.hasSnack) {
          const listForSnack = adjustedAllMealList.filter(
            (el: any) => el.mealType === 'snack',
          );
          updateMealList = [...updateMealList, ...listForSnack];
        }
      }
      thisTotalAvgMacro = countListAvgMacro(updateMealList, mealDaysListLen);
      updateMealListId = updateMealList.map((a: any) => a._id);
    }
    if (brandName == 'NOSHLife') {
      var numDay = parseInt(selectedDays, 10);
      switch (numDay) {
        case 20:
          updatedtotalPrice += 650;
          setConsultationFee(650);
          setConsultationSession(3);
          break;
        case 30:
          updatedtotalPrice += 800;
          setConsultationFee(800);
          setConsultationSession(4);
          break;
        case 40:
          updatedtotalPrice += 950;
          setConsultationFee(950);
          setConsultationSession(5);
          break;
        case 50:
          updatedtotalPrice += 0;
          setConsultationFee(0);
          setConsultationSession(6);
          break;
      }
    }
    updatedtotalPrice *= numPeople;
    updatedtotalPrice += popupExtraCharge * numPeople;
    setTotalPrice(updatedtotalPrice);
    setAllMealForCheckout(updateMealListId);
    setTotalMacro(thisTotalAvgMacro);
  }, [
    allMealList,
    breakfastSelected,
    dinnerSelected,
    goal,
    skipDays,
    lunchSelected,
    numPeople,
    popupExtraCharge,
    startDate,
    selectedDays,
  ]);

  // useEffect(() => {
  // 	console.log('allMealDatesForCheckout', allMealDatesForCheckout);
  // }, [allMealDatesForCheckout]);

  const packages = [
    { value: '2', label: '2 Days' },
    { value: '3', label: '3 Days' },
    { value: '4', label: '4 Days' },
    { value: '5', label: '5 Days' },
    { value: '10', label: '10 Days' },
    { value: '15', label: '15 Days' },
    { value: '20', label: '20 Days' },
    { value: '30', label: '30 Days' },
    { value: '40', label: '40 Days' },
    { value: '50', label: '50 Days' },
    { value: '60', label: '60 Days' },
  ];

  // Handle the skip days
  const updateSkipDays = (date: string) => {
    // const newSkipDays = skipDays;
    if (skipDays.includes(date)) {
      // Already exist
      const newSkipDays = skipDays.filter((exclusion) => exclusion !== date);
      setSkipDays(newSkipDays);
    } else {
      // Add new
      const newSkipDays = [...skipDays, date];
      setSkipDays(newSkipDays);
    }
  };

  return (
    <Layout>
      <AddOnPopup
        token={token}
        visible={AddOnPopupVisible}
        // data={userCart}
        data={userCart ? userCart.addOns || {} : null}
        datesList={
          allMealDatesForCheckout &&
          allMealDatesForCheckout.map((date: any) =>
            moment(date).utcOffset(8).format(dateFormat),
          )
        }
        onClosePopup={() => {
          setAddOnPopupVisible(false);
        }}
        onFinishAddOn={(settings) => {
          setAddOnsToAdd(settings);
          if (AddOnPopupMode === 'checkout') {
            doCheckout(settings);
          } else {
            setAddOnPopupVisible(false);
          }
        }}
        btn1Text={
          AddOnPopupMode === 'checkout'
            ? 'Confirm and Proceed to Checkout'
            : undefined
        }
        btn2Text={
          AddOnPopupMode === 'checkout'
            ? 'Confirm and Proceed to Checkout'
            : undefined
        }
        isEdit={false}
      />
      <main className="steps-container relative step4-preview">
        {/* {checkout ? <Redirect to="/Checkout" /> : ''} */}
        {loading ? <LoadingScreen /> : ''}
        <Steps
          step={4}
          links={[
            '/MealPlans/1',
            `/MealPlans/${brandName}`,
            `/MealPlans/package/${brandName}/${goalName}`,
            '',
            '',
          ]}
        />
        <form className="step3-container">
          <h1 className="step4-preview-title">{t('mealPlan.step4Title')}</h1>
          <div className="left-container">
            <div className="plan-info">
              <div className="plan-info-box">
                <div className="plan-info-name">
                  <h2 className="plan-info-brand">
                    <span>{t('mealPlan.brand')}:</span>
                    <span>{goal && goal.cuisineId.name}</span>
                  </h2>
                  <h3 className="plan-info-title">
                    <span>{t('mealPlan.goal')}:</span>
                    <span>{goal && goal.name}</span>
                  </h3>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  {brandName == 'NOSHLife' ? (
                    <h3 className="plan-info-title">
                      <span>
                        <div className="plan-info-consultation">{`- Includes ${consultationSession} consultations with Expert Dietician.`}</div>
                        <br></br>
                      </span>
                    </h3>
                  ) : (
                    ''
                  )}
                </div>
                <div className="plan-info-price-holder">
                  <div className="plan-info-price">{`HK $ ${perDayPrice}`}</div>
                  <div className="plan-info-per-day">
                    {t('mealPlan.perDay')}
                  </div>
                </div>
              </div>
              <div className="plan-info-helper">
                {t('mealPlan.moreAboutThe')}
                <button
                  type="button"
                  onClick={() => openPlanDetailsPopup()}
                  className="plan-info-helper-link"
                >
                  {goal && goal.name}
                </button>
                {brandName == 'NOSHLife' ? (
                  <div>
                    <p>
                      If you have questions about our meal plans or would like
                      to arrange a dietician consultation, please get in touch
                      with our team at{' '}
                      <a href="mailto:hello@nosh.hk" target="_blank">
                        <span>hello@nosh.hk</span>
                      </a>
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="nutrition-container hide-mobile">
              <div className="nutrition-title">
                <div className="nutrition-main-label">
                  {' '}
                  {t('mealPlan.avgMacroGram')}
                </div>
                <span className="nutrition-main-hyphen">-</span>
                <div className="nutrition-main-label">
                  {' '}
                  {t('mealPlan.gram')}
                  (G){' '}
                </div>
              </div>

              <div className="left-border">
                <div className="nutrition-label">
                  {t('mealPlan.calories').toUpperCase()}
                </div>
                <div className="nutrition-value">
                  {totalMacro.total_calories}
                </div>
              </div>

              <div className="left-border">
                <div className="nutrition-label">{t('mealPlan.protein')}</div>
                <div className="nutrition-value">
                  {totalMacro.total_protein}
                </div>
              </div>

              <div className="left-border">
                <div className="nutrition-label">{t('mealPlan.carbs')}</div>
                <div className="nutrition-value">{totalMacro.total_carb}</div>
              </div>

              <div className="left-border">
                <div className="nutrition-label">{t('mealPlan.fats')}</div>
                <div className="nutrition-value">{totalMacro.total_fat}</div>
              </div>
            </div>
          </div>
          {/* Mobile Price Info (Top) */}
          <div className="checkout-price-mobile show-mobile">
            <div className="step3-container">
              <div className="right-container">
                <div className="checkout-container">
                  <div className="total-text">
                    {`${t('total')}: HK$ ${totalPrice}`}
                  </div>

                  <button
                    type="button"
                    onClick={onCheckout}
                    className={
                      startDate && selectedMealTypeNum > 1 ? '' : 'is-disabled'
                    }
                    disabled={!(startDate && selectedMealTypeNum > 1)}
                  >
                    {t('mealPlan.confirmMealPlan')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* END Mobile Price Info (Top) */}
          {/* Choose Package and How Many People */}
          <div className="right-container">
            <div className="meal-options-container mod-mobile-row">
              <div className="meal-options-item mod-mobile-half">
                <div className="step3-label-text">
                  {t('mealPlan.choosePackage')}
                </div>
                <div className="meal-options-choice-container">
                  <Select
                    className="custom-select"
                    options={packages.filter(
                      (item: any) =>
                        goal &&
                        goal.allowedDays.includes(parseInt(item?.value, 10)),
                    )}
                    value={{
                      value: selectedDays,
                      label: `${selectedDays} Days`,
                    }}
                    onChange={(e) => updatePackage(e)}
                  />
                </div>
              </div>

              <div className="meal-options-item mod-mobile-half">
                <div className="step3-label-text">
                  {t('mealPlan.howManyPeople')}
                </div>
                <div className="meal-options-choice-container">
                  <button type="button" onClick={onDecreaseNumPeople}>
                    <div className="num-people-button-text step3-text">-</div>
                  </button>
                  <div className="num-people-text">{numPeople}</div>
                  <button type="button" onClick={onIncreaseNumPeople}>
                    <div className="num-people-button-text step3-text">+</div>
                  </button>
                </div>
              </div>
            </div>
            {/* END Choose Package and How Many People */}
            <div className="meal-options-seperater hide-mobile" />

            <div className="meal-options-container mod-top-options">
              <div className="meal-options-item">
                <div className="step3-label-text">
                  {t('mealPlan.startDate')}{' '}
                  <i>{t('mealPlan.monWedOnlyAvailable')}</i>
                </div>
                <div className="meal-options-choice-container">
                  {startDateOpts ? (
                    <Select
                      className="custom-select"
                      options={startDateOpts}
                      value={
                        startDate
                          ? {
                              value: startDate,
                              label: `${moment(startDate, dateFormat)
                                .utcOffset(8)
                                .format(labelFormat)}`,
                            }
                          : {}
                      }
                      onChange={(e) => updateStartDate(e)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="meals-per-day-group step3-label-text">
                <div className="meals-per-day-label">
                  {t('mealPlan.howManyMealsPerDay')}
                </div>
                <div className="meals-per-day-button-group">
                  {/* {brandName &&
                  (brandName.toLowerCase().indexOf("noshlife") > -1 ||
                    brandName.toLowerCase().indexOf("noshleangreen") >
                      -1) ? null : (
                    <FACheckboxButton
                      onClick={onCheckboxBreakfast}
                      isSelected={breakfastSelected}
                      label={t("mealPlan.breakfast")}
                      showThreeMeals={
                        goal == null ? null : goal.showThreeMeals.breakfast
                      }
                    />
                  )} */}
                  {/* {brandName == "NOSHLife" ? (
                    <div>{t("mealPlan.breakfast")},</div>
                  ) : (
                    <span></span>
                  )}
                  {brandName != "NOSHLife" && brandName != "NOSHLeanGreen" ? (
                    <FACheckboxButton
                      onClick={onCheckboxLunch}
                      isSelected={lunchSelected}
                      label={t("mealPlan.lunch")}
                      showThreeMeals={
                        goal == null ? null : goal.showThreeMeals.lunch
                      }
                    />
                  ) : (
                    <div>{t("mealPlan.lunch")},</div>
                  )}
                  {brandName != "NOSHLife" && brandName != "NOSHLeanGreen" ? (
                    <FACheckboxButton
                      onClick={onCheckboxDinner}
                      isSelected={dinnerSelected}
                      label={t("mealPlan.dinner")}
                      showThreeMeals={
                        goal == null ? null : goal.showThreeMeals.dinner
                      }
                    />
                  ) : (
                    <div>{t("mealPlan.dinner")}</div>
                  )} */}

                  {/* NEW Selection Logic */}
                  {/* { goal != null && goal._id == "65aa458eff987703b13642fc" ? "3" : ""} */}
                  {goal != null &&
                  goal.showThreeMeals.breakfast.show === true ? (
                    <FACheckboxButton
                      onClick={onCheckboxBreakfast}
                      isSelected={breakfastSelected}
                      label={
                        goal._id != '65aa458eff987703b13642fc'
                          ? t('mealPlan.breakfast')
                          : 'Meal 1'
                      }
                      showThreeMeals={
                        goal == null ? null : goal.showThreeMeals.breakfast
                      }
                    />
                  ) : (
                    ''
                  )}
                  {goal != null && goal.showThreeMeals.lunch.show === true ? (
                    <FACheckboxButton
                      onClick={onCheckboxLunch}
                      isSelected={lunchSelected}
                      label={
                        goal._id != '65aa458eff987703b13642fc'
                          ? t('mealPlan.lunch')
                          : 'Meal 2'
                      }
                      showThreeMeals={
                        goal == null ? null : goal.showThreeMeals.lunch
                      }
                    />
                  ) : (
                    ''
                  )}
                  {goal != null && goal.showThreeMeals.dinner.show === true ? (
                    <FACheckboxButton
                      onClick={onCheckboxDinner}
                      isSelected={dinnerSelected}
                      label={
                        goal._id != '65aa458eff987703b13642fc'
                          ? t('mealPlan.dinner')
                          : 'Meal 3'
                      }
                      showThreeMeals={
                        goal == null ? null : goal.showThreeMeals.dinner
                      }
                    />
                  ) : (
                    ''
                  )}
                  <div className="meals-per-day-msg-wrapper">
                    {selectedMealTypeNum > 2 ||
                    (goal &&
                      (goal.name === 'Keto Lite' ||
                        brandName === 'NOSHLeanGreen') &&
                      selectedMealTypeNum >= 2) ? (
                      <div className="meals-per-day-msg">
                        <p>
                          {goal && goal.hasSnack ? (
                            <span className="meals-per-day-iconmsg mod-breakfast">
                              <img
                                className="meals-per-day-iconmsg-icon snack-icon"
                                src="/img/assets/snack-green.png"
                                alt=""
                              />
                              {t('mealPlan.snackIncluded')}
                            </span>
                          ) : (
                            ''
                          )}
                          {/* <span className="meals-per-day-msg-comma">,</span>
													{(breakfastDiscount) ? (
														<span className="meals-per-day-iconmsg mod-breakfast-discount">
															{t('checkout.breakfastDiscount1')}
															{Math.abs(breakfastDiscount)}
															{' '}
															{t('checkout.breakfastDiscount2')}
														</span>
													) : ''} */}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {selectedMealTypeNum > 1 ? (
                      <div className="meals-per-day-msg">
                        <p>
                          {!breakfastSelected &&
                          lunchSelected &&
                          dinnerSelected &&
                          brandName.toLowerCase().indexOf('noshleangreen') ==
                            -1 &&
                          goal?._id != '65aa458eff987703b13642fc' ? (
                            <span className="meals-per-day-iconmsg mod-breakfast">
                              <img
                                className="meals-per-day-iconmsg-icon"
                                src="/img/assets/breakfast.png"
                                alt=""
                              />
                              {t('mealPlan.breakfastReminder')}
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {selectedMealTypeNum < 2 ? (
                      <p className="meals-per-day-warning">
                        {t('mealPlan.selectAtLeast2Meals')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="meal-options-container">
              {brandName &&
              (brandName.toLowerCase().indexOf('comfortfood') > -1 ||
                brandName.toLowerCase().indexOf('noshketo') > -1 ||
                brandName.toLowerCase().indexOf('noshleangreen') > -1 ||
                goal?._id == '65aa458eff987703b13642fc') ? null : (
                <div className="meal-options-item">
                  <div className="meal-options-choice-container">
                    <button
                      type="button"
                      className="special-request-button"
                      onClick={onSpecialRequest}
                    >
                      <span className="step3-text">
                        {t('mealPlan.addSpecialRequest')}
                      </span>
                      <img alt="avocado" src="/img/avocado.svg" />
                    </button>
                  </div>
                </div>
              )}
              <div className="meal-options-item mod-addon">
                <div className="meal-options-choice-container">
                  <button
                    type="button"
                    className="special-request-button mod-addon"
                    onClick={openAddOn}
                  >
                    <span className="step3-text">{t('mealPlan.addAddOn')}</span>
                    <img alt="Add On" src="/img/assets/icon-add-on.png" />
                  </button>
                </div>
              </div>
            </div>
            <div className="nutrition-container-mob show-mobile">
              <div className="nutrition-container">
                <div className="nutrition-title">
                  <div className="nutrition-main-label">
                    {' '}
                    {t('mealPlan.avgMacroGram')}
                  </div>
                  <span className="nutrition-main-hyphen">-</span>
                  <div className="nutrition-main-label">
                    {' '}
                    {t('mealPlan.gram')}
                    (G){' '}
                  </div>
                </div>

                <div className="left-border">
                  <div className="nutrition-label">
                    {t('mealPlan.calories').toUpperCase()}
                  </div>
                  <div className="nutrition-value">
                    {totalMacro.total_calories}
                  </div>
                </div>

                <div className="left-border">
                  <div className="nutrition-label">{t('mealPlan.protein')}</div>
                  <div className="nutrition-value">
                    {totalMacro.total_protein}
                  </div>
                </div>
                <div className="nutrition-break" />
                <div className="left-border">
                  <div className="nutrition-label">{t('mealPlan.carbs')}</div>
                  <div className="nutrition-value">{totalMacro.total_carb}</div>
                </div>

                <div className="left-border">
                  <div className="nutrition-label">{t('mealPlan.fats')}</div>
                  <div className="nutrition-value">{totalMacro.total_fat}</div>
                </div>
              </div>
            </div>

            <div className="right-bottom-group hide-mobile">
              <div />
              <div className="checkout-container">
                <div className="show-mobile price">
                  {`HK $ ${perDayPrice} / Day`}
                </div>

                <div className="total-text">
                  {`${t('total')}: HK$ ${totalPrice}`}
                </div>

                <button
                  type="button"
                  onClick={onCheckout}
                  className={
                    startDate && selectedMealTypeNum > 1 ? '' : 'is-disabled'
                  }
                  disabled={!(startDate && selectedMealTypeNum > 1)}
                >
                  {t('mealPlan.confirmMealPlan')}
                </button>
              </div>
            </div>

            <div className="meal-table-tophead">{t('mealPlan.weeklyMenu')}</div>
            <p className="meal-table-mob-hints">
              {t('mealPlan.mealTableMobHints')}
            </p>
            <div className="week-pickers radio-pickers">
              <div className="week-pickers radio-pickers-row">
                {weekOpts &&
                  weekOpts.map((dateVal: any, index: number) => {
                    const output = [];
                    output.push(
                      <div
                        key={`weeks-${dateVal.id}`}
                        className="week-picker radio-picker"
                      >
                        <input
                          id={`weeks-${index}`}
                          type="radio"
                          name="weeks"
                          value={dateVal.period}
                          checked={weekOpt === dateVal.period}
                          onChange={() => updateWeek(dateVal.period)}
                        />
                        <label
                          className="radio-picker-label"
                          htmlFor={`weeks-${index}`}
                        >
                          <span className="radio-picker-txt">
                            {dateVal.label}
                          </span>
                        </label>
                      </div>,
                    );
                    return output;
                  })}
              </div>
            </div>
          </div>
        </form>
        {mealList && mealList.length > 0 ? (
          <MealPlanDetailFooter
            goal={goal}
            mealList={mealList}
            holidays={holidays}
            skipDays={skipDays}
            hasSnack={
              goal
                ? (goal.hasSnack && selectedMealTypeNum > 2) ||
                  (goal.hasSnack && brandName === 'NOSHLeanGreen')
                : false
            }
            hasExtraMeal={goal ? goal.hasExtraMeal : false}
            hasExtraLunch={goal ? goal.hasExtraLunch && lunchSelected : false}
            hasExtraDinner={
              goal ? goal.hasExtraDinner && dinnerSelected : false
            }
            allMealDatesForCheckout={allMealDatesForCheckout}
            updateSkipDays={updateSkipDays}
          />
        ) : (
          <div className="meal-table-noresult">
            {t('mealPlan.noWeeklyMenuForThisWeek')}
          </div>
        )}
        {/* END Mobile Price Info (Bottom) */}
        <div className="checkout-price-mobile mod-bottom show-mobile">
          <div className="step3-container">
            <div className="right-container">
              <div className="checkout-container">
                <div className="show-mobile price">
                  {`HK $ ${perDayPrice} / ${t('day')}`}
                </div>

                <div className="total-text">
                  {`${t('total')}: HK$ ${totalPrice}`}
                </div>

                <button
                  type="button"
                  onClick={onCheckout}
                  className={
                    startDate && selectedMealTypeNum > 1 ? '' : 'is-disabled'
                  }
                  disabled={!(startDate && selectedMealTypeNum > 1)}
                >
                  {t('mealPlan.confirmMealPlan')}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END Mobile Price Info (Bottom) */}
        <SpecialDetailsPopup
          visible={showPopup}
          selectedExclusions={selectedExclusions}
          exclusions={popupExclusions}
          onExclusionsChange={onPopupExclusionChange}
          // selectedProteinChange={selectedProteinChange}
          // proteinChanges={popupProteinChange}
          // onProteinChange={onPopupProteinChange}
          extraCharge={popupExtraCharge}
          onConfirm={onPopupConfirm}
        />
        <PlanDetailsPopup
          visible={showPlanDetailsPopup}
          onClosePopup={closePlanDetailsPopupConfirm}
          goal={goal}
        />
      </main>
    </Layout>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  const {
    match: {
      params: { brand, goal, days },
    },
  } = props;
  return {
    token: selectToken(state),
    userCart: selectUserCart(state),
    brandName: brand,
    goalName: goal,
    days,
  };
};

export default connect(mapStateToProps)(Preview);
