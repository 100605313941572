import React from 'react';
import './PlanDetailsPopup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Goal } from '../../../../models';
import PlanMenuPreview from './PlanMenuPreview';

type Props = {
  visible: boolean;
  onClosePopup: () => void;
  goal: Goal | null;
};
const PlanDetailsPopup = ({ onClosePopup, visible, goal }: Props) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <div className="plan-popup">
      <button
        type="button"
        className="plan-popup-overlay-close"
        onClick={onClosePopup}
      >
        {t('close')}
      </button>
      <div className="plan-popup-body">
        <button
          type="button"
          className="plan-popup-close"
          onClick={onClosePopup}
        >
          <FontAwesomeIcon icon={faTimes} size="3x" />
        </button>
        <div className="plan-popup-container">
          <div className="plan-popup-content">
            <h3 className="plan-popup-title">
              {t('about')}{' '}
              <span className="plan-popup-plan-name">{goal && goal.name}</span>
            </h3>
            {/* <div className="plan-popup-features">
							<div className="plan-popup-feature">
								<img className="plan-popup-feature-icon" src="/img/assets/icon-fuel.png" alt="" />
								<div className="plan-popup-feature-desc">{t('mealPlan.detailPopupDesc1')}</div>
							</div>
							<div className="plan-popup-feature">
								<img className="plan-popup-feature-icon" src="/img/assets/icon-meat.png" alt="" />
								<div className="plan-popup-feature-desc">{t('mealPlan.detailPopupDesc2')}</div>
							</div>
							<div className="plan-popup-feature">
								<img className="plan-popup-feature-icon" src="/img/assets/icon-veggie.png" alt="" />
								<div className="plan-popup-feature-desc">{t('mealPlan.detailPopupDesc3')}</div>
							</div>
							<div className="plan-popup-feature">
								<img className="plan-popup-feature-icon" src="/img/assets/icon-veggie.png" alt="" />
								<div className="plan-popup-feature-desc">{t('mealPlan.detailPopupDesc4')}</div>
							</div>
						</div> */}
            <div className="plan-popup-break" />
            <p className="plan-popup-desc">{goal && goal.description}</p>
            <PlanMenuPreview goal={goal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetailsPopup;
