import React from 'react';
import moment from 'moment';
import './CartDropdownItem.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CartItem, GoalId, MealTypeIndex } from '../../../models/CartItem';

type Props = {
  onRemove: () => void;
  cartItem: CartItem;
  isExpired: boolean;
};

const formatMeals = (breakfast: boolean, lunch: boolean, dinner: boolean) => {
  const mealSelections = [];
  if (breakfast) mealSelections.push('Breakfast');
  if (lunch) mealSelections.push('Lunch');
  if (dinner) mealSelections.push('Dinner');

  return mealSelections.join(', ');
};

const CartDropdownItem = ({ onRemove, cartItem, isExpired }: Props) => {
  const { t } = useTranslation();
  console.log('cartItem', cartItem);
  const {
    goalId,
    noOfDays,
    startDate,
    breakfast,
    lunch,
    dinner,
    _price,
    quantity,
  } = cartItem;
  return (
    <div className="cart-dropdown-container">
      <div>
        {/* <img
					alt="meal-icon"
					src={goalId.package_image[noOfDays.toString() as MealTypeIndex] || goalId.imageURL}
				/> */}
        <span className="meal-cart-thumb">
          <div
            className="meal-cart-thumb-src"
            style={{ backgroundImage: `url(${goalId.imageURL})` }}
          />
        </span>
        <div className="contents">
          <button type="button" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </button>
          <p>{goalId.name}</p>
          <p>{`${noOfDays} days | quantity: ${quantity} | ${moment(startDate).format('DD MMM')} - ${moment(startDate).add(noOfDays, 'day').format('DD MMM')}`}</p>
          <p>{formatMeals(breakfast, lunch, dinner)}</p>
          <p className="-price">{`$${Math.ceil(_price * quantity)}`}</p>
          {isExpired ? (
            <p className="expire-msg">{t('layout.expiryMsg')}</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default CartDropdownItem;
