import { takeLatest, put, call } from 'redux-saga/effects';
import * as OrderActions from './actions';
import * as OrderService from '../../../api/OrderService';
import i18n from '../../../i18n/i18n';

export function* handleFetchOrders() {
  try {
    const response = yield call(OrderService.getOrders);
    const {
      data: { orders },
    } = response;
    yield put(OrderActions.fetchOrdersSuccess(orders));
  } catch (error) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error;
    yield put(
      OrderActions.fetchOrdersFail(
        message[i18n.getLang()] || 'Error occurred fetching orders',
      ),
    );
  }
}

export function* watchFetchOrders() {
  yield takeLatest(OrderActions.FETCH_ORDERS, handleFetchOrders);
}
