import { ActionType } from 'typesafe-actions';
import * as SettingActions from './actions';
import * as AuthActions from '../../auth/duck/actions';
import { Banner } from '../../../models/Banner';

export interface State {
  banners: Array<Banner> | null;
  error?: string;
}

const initialState: State = {
  banners: null,
};

type SettingActions = ActionType<typeof SettingActions>;
type AuthActions = ActionType<typeof AuthActions>;

export default function reducer(
  state: State = initialState,
  action: SettingActions | AuthActions,
): State {
  switch (action.type) {
    case SettingActions.FETCH_BANNER:
      return {
        ...state,
        error: undefined,
      };
    case SettingActions.FETCH_BANNER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SettingActions.FETCH_BANNER_FAIL:
      return {
        ...state,
        error: action.payload.error,
      };
    case AuthActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
