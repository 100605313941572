import React from 'react';
import './LoadingScreen.scss';

const LoadingScreen = () => (
  <div className="loading-screen">
    <img
      className="loading-screen-icon"
      src="/img/icon-loading.gif"
      alt="Loading..."
    />
  </div>
);

export default LoadingScreen;
