import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from '../../layouts/Layout';
import './CorporateMeals.scss';

const CorporateMeals = () => {
  const { t } = useTranslation();

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <h1 className="a11y-hidden">Check out our Catering for Event Meals</h1>
      <Helmet>
        <title>Event & Junk Catering Service Hong Kong | NOSH</title>
        <meta
          name="description"
          content="Get top-notch event and junk catering service in HK from NOSH. We offer delicious, nutritious meals for all types of events for your company."
        />
      </Helmet>
      <main>
        <section className="corporate-meals-header">
          <div className="content">
            <h2 className="section-title">{t('corpMeals.title')}</h2>
            <p>{t('corpMeals.desc')}</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://eepurl.com/cOBLwj"
            >
              <button type="button">{t('corpMeals.startHere')}</button>
            </a>
          </div>
        </section>
        <section className="corporate-meals-features">
          <div className="content">
            <div className="features">
              <div className="feature">
                <img
                  alt="Corporate-PageArtboard-1"
                  src="/img/Corporate-PageArtboard-1.jpg"
                />
                <p>{t('corpMeals.bentoBox')}</p>
              </div>
              <div className="feature">
                <img
                  alt="Corporate-PageArtboard-2"
                  src="/img/Corporate-PageArtboard-2.jpg"
                />
                <p>{t('corpMeals.sharingPlatter')}</p>
              </div>
              <div className="feature">
                <img
                  alt="Corporate-PageArtboard-3"
                  src="/img/Corporate-PageArtboard-3.jpg"
                />
                <p>{t('corpMeals.lunchBox')}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="corporate-meals-catering-menu">
          <div className="content">
            <h2 className="section-title">{t('corpMeals.title2')}</h2>
            <p>{t('corpMeals.desc2')}</p>
            <div className="buttons">
              {/* <a target="_blank" rel="noopener noreferrer" href="/documents/NOSH_Holiday_Buffet_Menu_2020.pdf" className="holiday-buffet-menu">
								<button type="button">{t('corpMeals.holidayBuffet')}</button>
							</a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nosh-meal-plan-resources-v1.s3.ap-southeast-1.amazonaws.com/prod/menus/NOSH+XMAS+CATERING+MENU+2023-3.pdf"
                className="spoonful-menu"
              >
                <button type="button">
                  NOSH <br></br>
                  {t('corpMeals.festiveMenu')}
                </button>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/documents/NOSH_Corparate_Menu_2023_06_14.pdf"
                className="spoonful-menu"
              >
                <button type="button">
                  NOSH <br></br>
                  {t('corpMeals.corpJunkMenu')}
                </button>
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" href="/documents/NOSH_by_Spoonful_Catering_Menu_20_May_2020.pdf" className="nosh-menu">
								<button type="button">{t('corpMeals.noshCateringMenu')}</button>
							</a> */}
            </div>
          </div>
        </section>
        {/* <section className="corporate-meals-our-brands">
					<div className="content">
						<h1>{t('corpMeals.title3')}</h1>
						<p>
							{t('corpMeals.desc3')}
						</p>
						<Link to="/">
							<button type="button">{t('corpMeals.retailInfo')}</button>
						</Link>
					</div>
				</section> */}
      </main>
    </Layout>
  );
};

export default CorporateMeals;
