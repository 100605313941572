import axios from './axios';
import AppConfig from '../config/AppConfig';

export const getPaymentMethods = () => axios.get('v1/user/payment_methods');

export const updateUser = (data: {
  address: string;
  building: any | string;
  assignedZone: {};
  district: string;
  region: string;
  memberName: string;
  gender: string;
  secondAddressBuilding?: any | null;
  secondAddressFullAddress?: string | null;
  secondAddressDistrict?: string | null;
}) => axios.put('v1/user/', data);

export const getUser = () => axios.get('v1/user');

export const getPaymentInfo = () => axios.get('v1/user/payment_methods');

export const addPaymentMethod = (token: string) =>
  axios.post('v1/user/_add_card', {
    token,
  });

export const removeAllPaymentMethod = () =>
  axios.post('/v1/user/_remove_cards');

const changePassword = (password: string) =>
  axios.post('v1/user/change_password', {
    password,
  });

const subscribeToNewsletter = (fullName: string) =>
  axios.post('v1/user/_subscribe_mailchimp', {
    list_id: AppConfig.NEWSLETTER_LIST_ID,
    merge_fields: {
      FNAME: fullName,
    },
  });

export const mailchimpCart = (cartItems: any) =>
  axios.post('v1/user/_mailchimp_cart', {
    cartItems,
  });

export default {
  changePassword,
  updateUser,
  getUser,
  getPaymentInfo,
  addPaymentMethod,
  removeAllPaymentMethod,
  subscribeToNewsletter,
  mailchimpCart,
};
