import { all } from 'redux-saga/effects';
import { watchFetchBrands } from './duck/saga';
import BrandsReducer from './duck/reducer';
import Step1 from './Step1';
import Step3 from './Step3';

function* BrandsSaga() {
  yield all([watchFetchBrands()]);
}

export { BrandsSaga, BrandsReducer, Step1, Step3 };
