import React, { useEffect } from 'react';
import './Promo.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from 'ReduxTypes';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import UserService from '../../../api/UserService';
import { setToken } from '../../../api/axios';
import * as AccessService from '../../../api/AccessService';
import { selectToken } from '../../../store/auth/duck/selectors';
import * as AuthActions from '../../../store/auth/duck/actions';
import { selectNoshUser } from '../../../store/user/duck/selectors';
import { NoshUser } from '../../../models/NoshUser';

import Layout from '../../../layouts/Layout';

interface PromoProps {
  login: (username: string, password: string) => void;
  noshUser: NoshUser | null;
}

const PromoSignup = ({ login, noshUser }: PromoProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(noshUser);
    if (noshUser) history.push('/');
  }, [noshUser]);

  const validatePhone = (tel: any) =>
    validator.isMobilePhone(tel) && /^\d+$/.test(tel);

  const { register, handleSubmit, errors, triggerValidation } = useForm();

  const onSignUp = (data: any) => {
    const { username, name, gender, email, password, phone } = data;

    AccessService.signup(username, name, gender, email, password, Number(phone))
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const {
            data: { token },
          } = response;
          setToken(token);
          // Timeout so BE can properly save the new user
          setTimeout(() => {
            UserService.subscribeToNewsletter(name);
          }, 500);
          login(email, password);
        } else {
          window.alert(t('error.errSignUp'));
        }
      })
      .then(() => {
        console.log('history');
        history.push('/promo/thankyou');
      });
    // .then(() => {
    // 	AccessService.subscribePromo({ email }).then((response) => {
    // 		console.log('response', response);
    // 		setPromoCode(response.data.coupon.code);
    // 	});
    // }).catch((err) => {
    // 	const { response: { data: { error: { message } } } } = err;
    // 	if (message[i18n.getLang()]) {
    // 		window.alert(message[i18n.getLang()]);
    // 	} else {
    // 		window.alert(message);
    // 	}
    // });
  };

  return (
    <Layout>
      <div className="promo">
        <div className="promo-left left-1">
          <img className="logo-img" src="/img/promo-logo.png" alt="logo" />
          <h2 className="promo-h2">{t('promo.signup.title1')}</h2>
          <h1 className="promo-h1">{t('promo.signup.title2')}</h1>
          <h2 className="promo-h2">{t('promo.signup.title3')}</h2>

          <form className="login-form" onSubmit={handleSubmit(onSignUp)}>
            <label htmlFor="email-input">
              <span className="login-form-label">
                {t('layout.emailAddressReq')}
              </span>
              <input
                className="promo-input"
                id="email-input"
                name="email"
                type="email"
                placeholder={t('layout.emailAddressReq')}
                onChange={() => triggerValidation('email')}
                ref={register({
                  validate: {
                    isEmail: (value) =>
                      validator.isEmail(value) ||
                      t('form.invalidEmail').toString(),
                  },
                })}
              />
              {errors.email && <p className="error">{errors.email.message}</p>}
            </label>

            <label className="login-form-hidden-field" htmlFor="username">
              <span className="login-form-label">
                {t('layout.usernameReq')}
              </span>
              <input
                placeholder={t('layout.usernameReq')}
                id="username"
                name="username"
                type="text"
                ref={register({ required: true })}
                value="username"
                readOnly
              />
            </label>

            <label htmlFor="name">
              <span className="login-form-label">{t('layout.nameReq')}</span>
              <input
                className="promo-input"
                placeholder={t('layout.nameReq')}
                id="name"
                name="name"
                type="text"
                ref={register({ required: true })}
              />
            </label>

            <label htmlFor="phone-input">
              <span className="login-form-label">
                {t('layout.phoneNumberReq')}
              </span>
              <input
                className="promo-input"
                id="phone-input"
                type="tel"
                name="phone"
                placeholder={t('layout.phoneNumberReq')}
                onChange={() => triggerValidation('phone')}
                ref={register({
                  validate: {
                    isPhoneNumber: (value) =>
                      validatePhone(value) ||
                      t('form.invalidPhoneNumber').toString(),
                  },
                })}
              />
              {errors.phone && <p className="error">{errors.phone.message}</p>}
            </label>

            <label htmlFor="password-input">
              <span className="login-form-label">
                {t('layout.passwordReq')}
              </span>
              <input
                className="promo-input"
                id="password-input"
                name="password"
                placeholder={t('layout.passwordReq')}
                onChange={() =>
                  triggerValidation(['password', 'password_repeat'])
                }
                type="password"
                ref={register({
                  required: t('form.passwordRequired').toString(),
                  minLength: { value: 8, message: t('form.invalidPWLength') },
                })}
              />
              {errors.password && (
                <p className="error">{errors.password.message}</p>
              )}
            </label>
            <button className="promo-btn" type="submit">
              SUBSCRIBE
            </button>
          </form>
          <p className="promo-desc signup-desc">
            {t('promo.signup.description')}
          </p>
        </div>
        <img src="/img/promo-1.png" alt="food" className="promo-img" />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectToken(state),
  noshUser: selectNoshUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (username: string, password: string) =>
    dispatch(AuthActions.login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoSignup);
