/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import GiftCardsStep1 from './GiftCardsStep1';
import './GiftCardsSelector.scss';
import GiftCardsBillingInfo from './GiftCardsBillingInfo';
import GiftCardsFinal from './GiftCardsFinal';
import Layout from '../../../layouts/Layout';

function GiftCardsSelector(props: any) {
  const giftDetails = {
    email: '',
    customerName: '',
    giftTotal: '',
    message: '',
    giftCode: '',
  };
  const billingInfo = {
    yourName: '',
    yourEmail: '',
    cardNumber: '',
    cardCVC: '',
    cardExpireDate: '',
  };
  const finalData = {
    sendTo: giftDetails.email,
    message: giftDetails.message,
    amount: 9999,
    //amount: giftDetails.giftTotal,
    giftCode: 'this will be generated by backend',
  };
  const handleSaveGiftDetails = (data: any) => {
    Object.assign(giftDetails, {
      email: data.email,
      customerName: data.customerName,
      giftTotal: data.giftTotal,
      message: data.message,
    });

    // giftDetails.email = data.email;
    // giftDetails.customerName = data.customerName;
    // giftDetails.giftTotal = data.giftTotal;
    // giftDetails.message = data.message;
  };

  const handleSaveBillingInfo = (data: {
    yourName: string;
    yourEmail: string;
    cardNumber: string;
    cardCVC: string;
    cardExpireDate: string;
  }) => {
    billingInfo.yourName = data.yourName;
    billingInfo.yourEmail = data.yourEmail;
    billingInfo.cardNumber = data.cardNumber;
    billingInfo.cardCVC = data.cardCVC;
    billingInfo.cardExpireDate = data.cardExpireDate;
  };
  const handleSubmitData = () => {
    console.log('you submitted these data');
    console.log('first data', giftDetails);
    console.log('second data', billingInfo);
  };
  console.log('{props.match.path}', `${props.match.path}`);

  return (
    <Layout>
      <Router>
        <Switch>
          <Route exact path={props.match.path}>
            <GiftCardsStep1
              data={giftDetails}
              onHandleSaveData={handleSaveGiftDetails}
            />
          </Route>
          <Route exact path={`${props.match.path}billingInfo`}>
            <GiftCardsBillingInfo
              detailData={giftDetails}
              data={billingInfo}
              onHandleSaveData={handleSaveBillingInfo}
            />
          </Route>
          <Route exact path={`${props.match.path}final`}>
            <GiftCardsFinal
              finalData={giftDetails}
              onHandleSubmitData={handleSubmitData}
            />
          </Route>
        </Switch>
      </Router>
    </Layout>
  );
}

export default GiftCardsSelector;
