import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RootState } from 'ReduxTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swiper from 'swiper';
import { fetchAddOns, submitAddOns } from '../../../../api';
import * as CartActions from '../../../../store/cart/duck/actions';
import 'swiper/css/swiper.css';
import './AddOnPopup.scss';

type Props = {
  token: any;
  visible: boolean;
  isEdit: boolean;
  datesList?: any;
  data?: any;
  btn1Text?: string;
  btn2Text?: string;
  onFinishAddOn: (setting?: any) => void;
  onClosePopup: () => void;
  fetchCart: () => void;
};
const dateFormat = 'DD-MM-YYYY';
const labelFormat = 'MMMM, D, YYYY (ddd)';

export const AddOnPopup = ({
  token,
  btn1Text = 'Save Selected Add-ons',
  btn2Text = 'Save Selected Add-ons',
  visible,
  isEdit,
  data,
  onClosePopup,
  datesList,
  onFinishAddOn,
  fetchCart,
}: Props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const [addOns, setAddOns] = useState<any>(null);
  const [dateRange, setDateRange] = useState<any>(null);
  const [addOnSlider, setAddOnsSlider] = useState<any>(null);
  const [selectedAddOn, setSelectedAddOn] = useState<any>(null);
  const [addOnSetting, setAddOnSetting] = useState<any>({}); // Final Setting

  useEffect(() => {
    if (!token) return;
    // We previously allow user to choose "neverShowAddOn"
    // Now it's hidden, so on page load we clear it in cases of any old records
    localStorage.removeItem('neverShowAddOn');
    // Fetch ADD ONS
    setTimeout(() => {
      fetchAddOns(false)
        .then((res: any) => {
          const dataAddOns = res.data.addons;
          setAddOns(dataAddOns);
        })
        .catch((err: any) => {
          // console.log(err.response);
          let errMSg = '';
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.error) {
                errMSg = err.response.data.error.message;
              }
            }
          }
          window.alert(`There is an error loading add ons: ${errMSg}`);
        });
    }, 150);
  }, [token]);

  const getSwiperIdFromAddOnId = (id: string) => {
    if (addOns) {
      return addOns.findIndex((addOn: any) => addOn._id === id);
    }
    return null;
  };
  const getAddOnIdFromSwiperId = (id: any) => {
    if (addOns) {
      return addOns[id]._id;
    }
    return null;
  };

  const initSwiper = () => {
    const swiper = new Swiper('.addon-popup-slider .swiper-container', {
      navigation: {
        nextEl: '.addon-popup-slider .swiper-button-next',
        prevEl: '.addon-popup-slider .swiper-button-prev',
      },
      observeParents: true,
      observer: true,
      slidesPerView: 1,
      spaceBetween: 20,
      watchOverflow: true,
      centerInsufficientSlides: true,
      on: {
        slideChange() {
          const tarId = getAddOnIdFromSwiperId(swiper.activeIndex);
          setSelectedAddOn(tarId);
        },
      },
    });
    setAddOnsSlider(swiper);
  };

  useEffect(() => {
    if (addOns && addOns.length) {
      setTimeout(() => {
        initSwiper();
      }, 150);
    }
  }, [addOns]);

  useEffect(() => {
    if (!data) return;
    setAddOnSetting(data);
  }, [data]);

  useEffect(() => {
    // Set date ranges
    if (datesList) {
      setDateRange(datesList);
    } else {
      // Dummy
      const thisStartDate = moment().format(dateFormat);
      const thisDateRanges = [
        thisStartDate,
        moment(thisStartDate, dateFormat).add(1, 'd').format(dateFormat),
        moment(thisStartDate, dateFormat).add(2, 'd').format(dateFormat),
      ];
      setDateRange(thisDateRanges);
    }
  }, [datesList]);

  const goBack = () => {
    setStep(0);
  };
  const goForward = () => {
    setStep(1);
  };
  const selectAddOn = (id: string) => {
    setSelectedAddOn(id);
    if (addOnSlider) {
      const tarIndex = getSwiperIdFromAddOnId(id);
      addOnSlider.slideTo(tarIndex);
    }
    goForward();
  };

  // const onChangeNeverShow = (e: any) => {
  // 	if (e.target.checked) {
  // 		localStorage.setItem('neverShowAddOn', 'Y');
  // 	} else {
  // 		localStorage.removeItem('neverShowAddOn');
  // 	}
  // };

  const getAddOnAmount = (date: any, id: any) => {
    let amount = 0;
    if (addOnSetting) {
      if (addOnSetting[date]) {
        addOnSetting[date].forEach((item: any) => item === id && amount++);
      }
    }
    return amount;
  };
  const addAddOn = (date: any, id: any) => {
    if (addOnSetting) {
      if (addOnSetting[date]) {
        setAddOnSetting({
          ...addOnSetting,
          [date]: [...addOnSetting[date], id],
        });
      } else {
        setAddOnSetting({
          ...addOnSetting,
          [date]: [id],
        });
      }
    } else {
      setAddOnSetting({ [date]: [id] });
    }
  };
  const removeAddOn = (date: any, id: any) => {
    if (addOnSetting) {
      if (addOnSetting[date]) {
        const thisArr = [...addOnSetting[date]];
        const thisPos = thisArr.indexOf(id);
        if (thisPos > -1) thisArr.splice(thisPos, 1);

        setAddOnSetting({
          ...addOnSetting,
          [date]: [...thisArr],
        });
      }
    }
    // setAddOnSetting({ date: [id] });
  };
  const onClickConfirm = () => {
    console.log('onClickConfirm', addOnSetting);
    submitAddOns(addOnSetting || {})
      .then((res: any) => {
        onFinishAddOn(addOnSetting);
        fetchCart();
      })
      .catch((err: any) => {
        // console.log(err.response);
        let errMSg = '';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.error) {
              errMSg = err.response.data.error.message;
            }
          }
        }
        console.log(err);
        window.alert(`There is an error submitting add ons: ${errMSg}`);
      });
  };

  const onClickSkip = () => {
    onFinishAddOn();
  };

  if (!token) return null;

  return (
    <>
      <div className={`addon-popup ${!visible ? 'mod-invisible' : ''}`}>
        <div className="addon-popup-body">
          <div className="addon-popup-body-inner">
            <div className="addon-popup-head">
              {step === 1 ? (
                <button
                  className="addon-popup-back"
                  type="button"
                  onClick={() => {
                    goBack();
                  }}
                >
                  <span>Back</span>
                </button>
              ) : (
                <div />
              )}
              <button
                className="addon-popup-close"
                type="button"
                onClick={() => {
                  onClosePopup();
                }}
              >
                <span className="addon-popup-close-graphic">close</span>
                <span className="addon-popup-close-text">close</span>
              </button>
            </div>
            <div
              className={`addon-popup-content ${step === 0 ? 'mod-active' : ''}`}
            >
              <h2 className="addon-popup-title">Add-On Products</h2>
              <div className="addon-popup-scroller">
                <div className="addon-popup-items">
                  {addOns &&
                    addOns.map((addOn: any) => {
                      const addOnId = addOn._id;
                      const addOnImg = addOn.image_url;
                      const addOnName = addOn.name;
                      const addOnAmount = addOn.amount;
                      const addOnDesc = addOn.description;
                      return (
                        <div className="addon-popup-item">
                          <div
                            className="addon-popup-item-img"
                            style={{ backgroundImage: `url(${addOnImg})` }}
                          />
                          <div className="addon-popup-item-holder">
                            <div className="addon-popup-item-head">
                              <h3 className="addon-popup-item-title">
                                {addOnName}
                              </h3>
                              <span className="addon-popup-item-price">
                                {addOnAmount} HKD
                              </span>
                            </div>
                            <p className="addon-popup-item-desc">{addOnDesc}</p>
                            <button
                              className="addon-popup-item-btn"
                              type="button"
                              onClick={() => {
                                selectAddOn(addOnId);
                              }}
                            >
                              {isEdit ? 'Edit Order' : 'Order'}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {!isEdit ? (
                <>
                  <div className="addon-popup-btns">
                    <button
                      type="button"
                      className="addon-popup-btn"
                      onClick={() => onClickConfirm()}
                    >
                      {btn1Text}
                    </button>
                  </div>
                  {/* <div className="addon-popup-btns">
										<button
											type="button"
											className="addon-popup-btn mod-yellow"
											onClick={() => onClickSkip()}
										>
											Proceed to Checkout
										</button>
									</div> */}
                  {/* <div className="addon-popup-display-opt">
										<div className="addon-popup-checkbox">
											<input
												className="addon-popup-checkbox-input"
												id="addon-popup-display-opt"
												type="checkbox"
												onChange={(e) => onChangeNeverShow(e)}
											/>
											<label className="addon-popup-checkbox-label" htmlFor="addon-popup-display-opt"><i>Never show this again</i></label>
										</div>
									</div> */}
                </>
              ) : null}
            </div>
            <div
              className={`addon-popup-content ${step === 1 ? 'mod-active' : ''}`}
            >
              <h2 className="addon-popup-title">
                Plan supplements for your week
              </h2>
              <div className="addon-popup-slider">
                <div className="swiper-control">
                  <button type="button" className="swiper-button-prev">
                    {t('mealPlan.prevSlide')}
                  </button>
                  <button type="button" className="swiper-button-next">
                    {t('mealPlan.nextSlide')}
                  </button>
                </div>
                <div className="swiper-container">
                  <div className="swiper-wrapper">
                    {addOns &&
                      addOns.map((addOn: any) => {
                        const addOnId = addOn._id;
                        const addOnImg = addOn.image_url;
                        const addOnName = addOn.name;
                        const addOnAmount = addOn.amount;
                        const addOnDesc = addOn.description;
                        return (
                          <div
                            key={addOnId}
                            className="addon-popup-slider-item swiper-slide"
                          >
                            <div className="addon-popup-item">
                              <div
                                className="addon-popup-item-img"
                                style={{ backgroundImage: `url(${addOnImg})` }}
                              />
                              <div className="addon-popup-item-holder">
                                <div className="addon-popup-item-head">
                                  <h3 className="addon-popup-item-title">
                                    {addOnName}
                                  </h3>
                                  <span className="addon-popup-item-price">
                                    {addOnAmount} HKD
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="addon-popup-scroller">
                <div className="addon-item-pickers">
                  {dateRange &&
                    dateRange.map((dateOpt: any) => {
                      const dateWeekDay = moment(dateOpt, dateFormat).format(
                        'dddd',
                      );
                      const dateDate = moment(dateOpt, dateFormat).format(
                        'MMMM, D, YYYY',
                      );
                      return (
                        <div className="addon-item-picker">
                          <div className="addon-item-picker-date-holder">
                            <div className="addon-item-picker-weekday">
                              {dateWeekDay}
                            </div>
                            <div className="addon-item-picker-date">
                              {dateDate}
                            </div>
                          </div>
                          <div className="addon-item-picker-control">
                            <button
                              type="button"
                              className="addon-item-picker-minus"
                              onClick={() =>
                                removeAddOn(dateOpt, selectedAddOn)
                              }
                            >
                              -
                            </button>
                            <div className="addon-item-picker-amount">
                              {getAddOnAmount(dateOpt, selectedAddOn)}
                            </div>
                            <button
                              type="button"
                              className="addon-item-picker-plus"
                              onClick={() => addAddOn(dateOpt, selectedAddOn)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="addon-popup-btns">
                <button
                  type="button"
                  className="addon-popup-btn"
                  onClick={() => onClickConfirm()}
                >
                  {!isEdit ? <>{btn2Text}</> : <>Confirm</>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  // token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCart: () => dispatch(CartActions.fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOnPopup);
