import React, { useEffect } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import MainRouter from './router';
import store from './store';
import i18nFeatures from './i18n/i18n';
import AppConfig from './config/AppConfig';

const stripePromise = loadStripe(AppConfig.STRIPE_KEY || '');

const App: React.FC = () => {
  const [cookies, setCookie] = useCookies();

  // useEffect(() => {
  // 	if (cookies) i18nFeatures.switchLanguage(cookies.lang || 'en');
  // }, [cookies]);

  return (
    <Elements stripe={stripePromise}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Helmet>
            <title>
              Healthy Meal Delivery | Healthy Weight Loss Meal Plan | NOSH
            </title>
            <meta
              name="description"
              content="Looking for healthy, delicious weight loss meal plans in HK? NOSH is your best option. Choose from our menu of healthy meals that are delivered straight to you."
            />
          </Helmet>
          <MainRouter />
        </Provider>
      </I18nextProvider>
    </Elements>
  );
};

export default App;
