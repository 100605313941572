const zht = {
  save: 'Save',
  tnc: {
    title: 'TERMS AND CONDITIONS FOR ONLINE SALE OF GOODS',
  },
  faq: {
    title: '常見問題',
    footer1: '找不到您想要的資料？',
    footer2: '請致電我們',
  },
  privacyPolicy: {
    title: 'PRIVACY POLICY',
  },
  weekdays: {
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    Friday: '星期五',
    Saturday: '星期六',
    Sunday: '星期日',
  },
  layout: {
    ourOfferings: '我們的服務',
    mealPlans: '精選食譜',
    ourMenu: '我們的菜單',
    checkoutOurMenu: '查看菜單',
    corporateLunch: '商務午餐',
    needHelp: '需要幫忙?',
    faq: '常見問題',
    deliveryFaq: '常見送餐問題',
    aboutNosh: '認識我們的供應商',
    blog: '部落格',
    getInTouch: '聯絡我們',
    joinUs: '加入我們',
    company: '公司',
    tnc: '條款及細則',
    privatePolicy: '私隱政策',
    addr: '柴灣新業街6號',
    foodFactoryLicense: '食物製造廠牌照: 2911209215',
    ourSuppliers: '我們的供應商',
    cateringJunks: '派對及活動到會',
    login: '登入',
    signOut: '登出',
    checkOut: '結賬',
    clearCart: '清空購物車',
    items: '項目',
    item: '項目',
    cart: '購物車',
    email: '電郵',
    password: '密碼',
    notRegistered: '尚未登記?',
    createAnAccount: '立即登記',
    signUp: '登記',
    emailAddressReq: '電郵*',
    usernameReq: '用戶名*',
    nameReq: '姓名*',
    phoneNumberReq: '電話*',
    confirmPasswordReq: '確認密碼*',
    alreadyRegistered: '己登記?',
    passwordReq: '密碼*',
    signIn: '登入',
    previousMessage: '上一訊息',
    nextMessage: '下一訊息',
    newsletterSubscribeCheckbox: 'Subscribe to our newsletter',
    shoppingBag: '購物袋',
    expiryMsg: '這項目已經過期，結賬前請先刪除。',
    or: '或',
    giftCards: '禮品卡',
    featuredMenuPlans: '精選食譜',
    checkOutOurMeals: '查看菜色',
    ambassadors: '大使',
    ourBrandPartners: '合作品牌',
    checkoutBrandPartners: '瀏覽其他合作品牌：',
  },
  forgotPw: {
    title: '重置密碼',
    desc: '填上你的電郵地址，我們將會傳送一個重置密碼的電郵給你。',
    button: '重置密碼',
    forgotPassword: '忘記密碼？',
    furtherInstructions:
      'We have sent you an email so please click on the link in your email and we’ll get your password changed in a jiffy.',
  },
  myAccount: {
    passwordChanged: 'Password changed successfully',
    signedInAs: '登入身份：',
    sharingIsCaring: 'Sharing is Caring',
    personalReferralCode: '您的',
    details: '詳情',
    name: '姓名:',
    email: '電郵:',
    building: '大廈:',
    address: '地址:',
    district: '區域:',
    addressBook: '地址簿',
    createSecondAddress: '建立第二地址',
    edit: '更改',
    changePassword: 'Change Password',
    save: '儲存',
    payment: 'PAYMENT',
    creditCardNo: '信用卡號碼',
    expiryDate: '有效期',
    cvc: '安全碼',
    recentOrders: '近期訂單',
    orderNo: '訂單號碼',
    orderDate: '訂單日期',
    orderEndDate: '訂單到期日',
    numberOfDay: '日數',
    products: 'Products',
    orderAmount: 'Order Amount',
    products2: '購買項目',
    orderAmount2: '總金額',
    status: '狀態',
    noshCredits: 'NOSH CREDITS',
    balance: '結餘',
    youHave: '您有',
    availableStoreCredit: '有效積分',
    cancel: '取消',
    copiedToClipboard: '已複製到剪貼板',
    deleteCard: '刪除信用卡',
    changeCard: '更改信用卡',
    districts: {
      newTerritories: 'New Territories',
      kowloon: 'Kowloon',
      hkIsland: 'HK Island',
    },
    form: {
      addressRequired: '地址為必須項目',
    },
    showAddress1: '切換至地址1',
    showAddress2: '切換至地址2',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
  },
  corpMeals: {
    title: '派對及活動到會',
    desc: '我們可以提供商務會議、小型會議和大型企業活動到會服務。具有彈性的菜單和定價，30人團餐到500人大型活動到會皆可包攬。',
    brand: '品牌',
    goal: '目標',
    startHere: '開始',
    bentoBox: 'Bento Box',
    sharingPlatter: 'Sharing Platter / Buffet',
    lunchBox: 'Lunch Box',
    title2: '看我們的餐飲菜單',
    desc2: '只需選擇你的菜系、人數及訂購日數，熱辣辣的美食將會每天為你送上。',
    corpLunchBox: 'Spoonful 企業到會',
    noshCateringMenu: 'NOSH 健康到會',
    junkMenu: '船河到會',
    festiveMenu: '聖誕餐單',
    corpJunkMenu: '企業及船和到會餐單',
    title3: '我們的品牌',
    desc3:
      'NOSH owns and operates two brands for its omni channel strategy where we sell to corporates, direct to consumer, and through retail outlets. Our mission is to design food for delivery and help Hong Kong eat smarter.',
    retailInfo: '零售訊息',
    holidayBuffet: 'HOLIDAY BUFFET MENU',
  },
  menu: {
    title: '我們的菜單',
    desc: '您可以提前預覽我們三週內的菜單. 我們中央廚房每天新鮮製造，為你送上一日三餐. 所有餐點都用環保的包裝送上，讓你食得健康又環保！圖片只供參考。',
    step1: '1. 選擇您喜歡的品牌',
    step2: '2. 選擇您想達到的目標',
    step3: '3. 選擇訂餐日期',
    orderNow: '立即下單',
  },
  mealPlan: {
    title: '精選食譜',
    desc: '香港首家虛擬食堂',
    brand: '品牌',
    goal: '目標',
    startNow: '立即開始',
    orderNow: '立即下單',
    title2: '食譜特色',
    desc2:
      '我們堅持每天新鮮製造，為你送上一日三餐，助你保持健康及達到營養目標。',
    feature1: '星期六早上九點前下單',
    feature2: '星級廚師炮製的食譜',
    feature3: '每日準時早上七時至九時送上',
    feature4: '營養豐富的膳食',
    title3: '我們的優勝之處',
    desc3:
      '我們堅持提供一個健康、方便及精明的餐單選擇。讓顧客能安於辦公室或家中，就能享用最新鮮及優質的食材。',
    bioPackaging: '可生物降解的包裝',
    title4: 'WANT TO HAVE NOSH AS YOUR LUNCH? ORDER THROUGH SPOONFUL',
    desc4:
      'Spoonful 應用程式現在可以在iOS和Android 的應用程式商店下載 ! 新功能包括NOSH會員推介和獎勵計劃',
    weeklyMenu: '預覽每週菜單',
    breakfast: '早餐',
    lunch: '午餐',
    dinner: '晚餐',
    snack: '小食',
    extra_meal: '額外美食',
    extra_lunch: '額外午餐',
    extra_dinner: '額外晚餐',
    breakfastDiscount1: '每份早餐減$',
    breakfastDiscount2: '',
    breakfastReminder: '同時訂購早午晚餐即可享受$20早餐優惠',
    daily: '每日平均',
    avgMacroGram: '主要營養',
    toggleMeal: '打開菜單',
    calories: '卡路里',
    protein: '蛋白質',
    carbs: '碳水化合物',
    fats: '脂肪',
    detailPopupDesc1: '補充一天的能量',
    detailPopupDesc2: '均衡主要營養',
    detailPopupDesc3: '每日攝取抗氧化物',
    detailPopupDesc4: '添加蛋白粉效果更佳',
    specialRequest: '特別飲食要求',
    specialDetailsFooter1: '*我們暫不能接受麵筋過敏和其他特殊要求。',
    specialDetailsFooter2:
      '所有產品皆由可處理牛奶，雞蛋，魚類（如鱸魚，比目魚，鱈魚），堅果（如杏仁，核桃，山核桃），花生，小麥和大豆等的食品工廠製作。',
    totalExtraCharge: '總計額外費用/早餐優惠:',
    orderCutOff: '截單時間已屆',
    thankYou: '謝謝!',
    orderCutOffIn: '截單時間',
    gram: '克',
    step1Title: '步驟 1: 選擇品牌',
    select: '選擇',
    brandContent: '新鮮素菜食譜\n優質入口蔬菜\n環保包裝',
    step2Title: '步驟 2',
    step2Desc: '選擇您的目標',
    nextSlide: '下一項目',
    prevSlide: '上一項目',
    chooseYourPackage: '選擇您的套餐',
    noChange: '不用更改',
    noBeef: '不要牛肉',
    noFish: '不要魚類',
    onlyFish: '只要魚類 (Pescatarian)',
    noPork: '不要豬肉',
    noSalmon: '不要三文魚',
    noTuna: '不要吞拿魚',
    perDay: '每天',
    moreAboutThe: '更多關於',
    startDate: '開始日期',
    endDate: '結束日期',
    monWedOnlyAvailable: '(只適用於星期一至三 )',
    choosePackage: '選擇套餐',
    howManyPeople: '用餐人數？',
    addSpecialRequest: '特別飲食要求',
    addAddOn: 'Extra Add-On Items',
    howManyMealsPerDay: '一日幾餐？',
    freeSnackIncluded: '包括免費小食',
    snackIncluded: '包括小食',
    selectAtLeast2Meals: '請選擇最少兩餐',
    confirmMealPlan: '結帳',
    noWeeklyMenuForThisWeek: '本週未有菜單',
    selectDays: '選擇日數',
    packageDesc:
      'These are credits that you can use anytime over a 30 day period. Think of us as a gym package for your belly!',
    mealTableMobHints:
      '點擊日期以瀏覽精選菜單。菜單中不會顯示小食。公眾假期及週未休息。如你選擇略去某些日子，該配額會在計劃最後補上。圖片只供參考。',
    packageLabel1: '由每日港幣',
    packageLabel2: '元起',
    specialRequestTitle1: '不含...',
    specialRequestNote1: '',
    specialRequestTitle2: '蛋白質改變',
    specialRequestNote2: '無額外收費',
    step1: '選擇品牌',
    step2: '選擇目標',
    step3: '選擇套餐',
    step4: '預覽',
    step5: '結帳',
    menuPreview: '菜單預覽',
    step4Title: '預覽訂單',
  },
  supplier: {
    title: 'Meet Our Suppliers',
    subtitle: 'Our Promise',
    desc: "At NOSH, we focus on every step of our ingredients' journeys. From field to cutting board, our sourcing teams work tirelessly to ensure that both premium and sustainable ingredients make it into every box.",
    desc2:
      "Our executive chef team has spent years developing both delicious and nutritious recipes. NOSH's meals are specifically designed for delivery and we are proud to use biodegradable packaging in all of our products.",
    desc3:
      'We know plastic waste is a serious problem worldwide and we want to do our part. Our packaging is fully designed out of both sugarcane and PLA plastics so that our customers can feel proud that the Nosh packaging decomposes in 51 days in a landfill. Eating better is also about leaving our planet better than how we found it.',
    feature: 'Grade A Grass Fed Beef',
    feature2: 'Hormone and GMO free Chicken',
    feature3: 'Organic Peruvian Quinoa',
    feature4: 'Sustainable Norwegian Salmon',
    videoDesc:
      "NOSH is proud to be a portfolio business with the Alibaba Entrepreneur Fund. Please see our Founder's message below regarding the business and the partnership.",
  },
  error: {
    errInitStripe: 'Stripe初始化錯誤',
    errUpdatingAddress: '更改地址錯誤: 無法讀取用戶資料',
    errCartAdd: '加入購物籃時發生錯誤',
    errValidUserAndPass: '請輸入有效的用戶名及密碼。',
    errSignUp: '登記錯誤，請再嘗試。',
    errAddOn: '此物品並不存在',
  },
  form: {
    invalidEmail: '請輸入有效電郵',
    invalidPhoneNumber: '請輸入有效電話',
    passwordRequired: '請輸入密碼',
    oldPasswordRequired: 'Old password required',
    newPasswordRequired: 'New password required',
    confirmPasswordRequired: 'New password required',
    invalidPWLength: '密碼最少包含因為8個字符。',
    pwNotMatching: '密碼不相符',
  },
  checkout: {
    useNewAddr: '使用新地址',
    addrReq: '地址為必填項目',
    addr4Char: '地址最少需含4個字符',
    addrNotRec: '我們無法辨認此地址，請檢查是否正確。',
    phoneReq: '聯絡電話為必填項目',
    phoneInvalid: '請提供正確的聯絡電話。',
    myCart: '您的購物車',
    date: '日期',
    startDate: '開始日期',
    endDate: '結束日期',
    package: '套餐',
    mealsPerDay: '每日幾餐',
    remove: '刪除',
    yourInfo: '您的購物車',
    deliveryInfo: '送貨資料',
    deliveryDates: '送貨日期',
    contactNum: '聯絡電話',
    sendMsg: '我們會利用電郵寄送資料給，請確認電郵無誤。',
    pickAddr: '選擇地址:',
    specialNotes: '送貨備註',
    chooseDeliveryTime: '您是否選擇特定送貨時間?',
    chooseDeliveryTimeCharge:
      '收費: 每日HK$25。通常於6:30-9am送貨。如有需要請選擇特定時段。',
    proceedToCheckout: '繼續結賬',
    continueToCheckout: '前往結帳',
    payment: '付款',
    subtotal: '小計',
    extraCharge: '額外收費',
    breakfastSavings: '早餐優惠',
    discountCode: '優惠碼或推薦碼',
    noshCredits: '使用 NOSH Credits',
    youHaveTotal: '',
    credits: 'Credits 餘額',
    total: '總計',
    cardNotes: '我們會透過 Stripe API 儲存你的付款資料以作將來交易之用',
    confirmAndPay: '確認及付款',
    deliveryDesc1: '你的送貨區域: ',
    deliveryDesc2: '運費: ',
    deliveryDesc3: '(每日)',
    deliveryFree: '此送貨區域免運費',
    deliveryPrice: '運費: ',
    youCanSave: '透過此優惠碼或推薦碼，你可節省',
    forThisDiscount: '。',
    noDiscount: '優惠碼不正確。',
    shoppingBag: '購物袋',
    breakfastDiscount1: '',
    breakfastDiscount2: 'HKD 減免早餐優惠',
    specialRequest: '特殊要求/早餐優惠',
    quantity: '數量',
    reminderEmail: '在計劃完結前電郵通知我！',
    donateFeedingHKTitle: 'Donate to Feeding HK',
    donateFeedingHKDesc: 'Help reduce food waste and feed those in need',
    donateFeedingRoundUp: 'Round up',
    donateFeedingGiveMore: 'Give more',
    moneyBackTitle: '退款保證:',
    moneyBackDesc: '訂餐首天後，若然您有任何不滿，請聯絡我們，我們會從速處理。',
  },
  checkoutSuccess: {
    title: '付款成功',
    desc: '您將會收到電郵確認訂單。',
    youHave: '您尚有',
    creditsForFuture: 'Nosh Credits 以作將來使用。',
    backToHome: '回到首頁',
    enjoy: 'Enjoy your NOSH and #eatsmarter',
    thanksAgain: 'Thanks again for your support, and #EATSMARTER',
    vipGetInTouch: 'If you have any questions, please get in touch!',
    vipTel:
      'Reach us at our Family & Friends only Whatsapp number 6771 0004 (8 am - 6:30 pm, Mon - Fri)',
    vipEmail: 'or email us at VIP@nosh.hk',
    vipNotes:
      '(We will do our best to get back to you asap after hours, or during public holidays!)',
    referralCode: '您的推薦碼',
    referralMsg: '記得分享您的推薦碼以賺取 100 HKD NOSH credits!',
  },
  newsletterWidget: {
    title:
      'Join to receive 15% OFF your first order and monthly perks. #EATSMARTER',
    button: 'Join the community',
  },
  promo: {
    signup: {
      title1: 'Subscribe to save',
      title2: '$350',
      title3: 'in your first order',
      description:
        '*By entering your email address you are subscribing to our periodic emails',
    },
    code: {
      title1: "here's your",
      title2: '$350 promo code',
      description: '*new customer and 10 days or above plan only',
    },
  },
  vip: {
    memberOfNoshFamilyAndFriends: 'MEMBER OF NOSH FAMILY & FRIENDS',
  },
  close: '關閉',
  about: '關於',
  confirm: '確認',
  days: '日',
  day: '日',
  hours: '小時',
  mins: '分鐘',
  secs: '秒',
  next: '繼續',
  week: '週',
  total: '總共',
  breakfast: '早餐',
  lunch: '午餐',
  dinner: '晚餐',
  snack: '小食',
  extra_meal: '額外美食',
  extra_lunch: '額外午餐',
  extra_dinner: '額外晚餐',
  hi: '您好',
  welcome: '歡迎回來',
  langKey: 'zh',
  langKey2: 'zht',
  langPath: '/zh_HK',
  loginWithFb: '使用Facebook登入',
};

export default zht;
