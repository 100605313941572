import { takeLatest, put, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { Cookies } from 'react-cookie';
import { setUserForGoogleTracking } from '../../../helpers/gaHelpers/gaHelpers';
import * as AuthActions from './actions';
import { fbLogin, login } from './actions';
import * as AccessService from '../../../api/AccessService';
import { NOSH_AUTH_DATA_KEY } from '../../../config/Keys';
import { setToken } from '../../../api/axios';
import i18n from '../../../i18n/i18n';

export function* handleLogin(action: ActionType<typeof login>) {
  try {
    const {
      payload: { username, password },
    } = action;
    // const hashedPw = sha256(password);
    const response = yield call(AccessService.login, username, password);
    const { data } = response;
    yield put(
      AuthActions.loginSuccess({
        token: data.token,
        authUser: data.user,
      }),
    );
    const cookies = new Cookies();
    const dataForNoshAuth = { ...data };
    // remove the gmaps data to avoid exceed cookies size
    if (dataForNoshAuth.user) {
      if (dataForNoshAuth.user.addressObject) {
        dataForNoshAuth.user.addressObject = {};
      }
      if (dataForNoshAuth.user.secondAddressBuilding) {
        if (dataForNoshAuth.user.secondAddressBuilding.gmaps) {
          dataForNoshAuth.user.secondAddressBuilding = {};
        }
      }
      cookies.set(NOSH_AUTH_DATA_KEY, JSON.stringify(dataForNoshAuth), {
        path: '/',
      });
      // Set user
      if (dataForNoshAuth.user.email) {
        setUserForGoogleTracking(dataForNoshAuth.user.email);
      }
    }
    setToken(data.token);
  } catch (error) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error;
    yield put(
      AuthActions.loginFail(
        message[i18n.getLang()] || 'Error occurred signing in',
      ),
    );
  }
}

export function* watchLogin() {
  yield takeLatest(AuthActions.LOGIN, handleLogin);
}

export function* handleFbLogin(action: ActionType<typeof fbLogin>) {
  const {
    payload: { name, email, accessToken, id },
  } = action;

  try {
    const response = yield call(
      AccessService.fbLogin,
      email,
      name,
      id,
      accessToken,
    );

    const {
      data,
      data: { token, user },
    } = response;

    yield put(
      AuthActions.loginSuccess({
        token,
        authUser: user,
      }),
    );
    const cookies = new Cookies();
    const dataForNoshAuth = {
      ...data,
      user: {
        addressObject: null,
        secondAddressBuilding: {},
      },
    };
    // Set user
    if (dataForNoshAuth.user.email) {
      setUserForGoogleTracking(dataForNoshAuth.user.email);
    }
    cookies.set(NOSH_AUTH_DATA_KEY, JSON.stringify(dataForNoshAuth), {
      path: '/',
    });

    setToken(token);
  } catch (error) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error;
    yield put(
      AuthActions.fbLoginFail(
        message[i18n.getLang()] || 'Error occurred signing in via facebook',
      ),
    );
  }
}

export function* watchFbLogin() {
  yield takeLatest(AuthActions.FB_LOGIN, handleFbLogin);
}
