/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import {
  selectToken,
  selectAuthUser,
} from '../../../store/auth/duck/selectors';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import GiftCardsInfo from './GiftCardsInfo';
import './GiftCardsBillingInfo.scss';

import { useCookies } from 'react-cookie';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import Axios from 'axios';
import instance, { getToken } from '../../../api/axios';
import UserService from '../../../api/UserService';
import GiftCardsService from '../../../api/GiftCardsService';
import { resolve } from 'dns';
import { Console } from 'console';
import { NOSH_AUTH_DATA_KEY } from '../../../config/Keys';
import LoadingScreen from '../../MealPlans/components/LoadingScreen/LoadingScreen';
import { RootState } from 'ReduxTypes';
import { Dispatch } from 'redux';
import { AuthUser } from '../../../models/AuthUser';

interface Props {
  authUser: AuthUser | null;
  token: string | null;
}

const stripeElementStyle = {
  base: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: '"Josefin Sans", sans-serif',
    width: '100%',
    height: '2rem',
  },
};

function GiftCardsBillingInfo(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [yourName, setYourName] = React.useState(props.data.yourName);
  const [yourEmail, setYourEmail] = React.useState(props.data.yourEmail);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loggedIn = document.querySelector('.logged-in-user');
    // component did mount alternative
    if (loggedIn) {
      setIsLoggedIn(true);
      console.log('mounted');

      UserService.getUser().then((res) => {
        setYourName(res.data.user.memberName);
        setYourEmail(res.data.user.email);
        // console.log(res.data.user.memberName);
      });
    }
  }, []);

  const [paymentMethodId, setPaymentMethodId] = useState<string>('');

  // Credit Card
  const stripe = useStripe();
  const elements = useElements();
  let stripeCardNumber: StripeCardNumberElement | null = null;
  let stripeCardExpiry: StripeCardExpiryElement | null = null;
  let stripeCardCvc: StripeCardCvcElement | null = null;

  const onSaveCC = () => {
    if (!stripe || !elements) return;

    stripeCardNumber = elements.getElement(CardNumberElement);
    stripeCardExpiry = elements.getElement(CardExpiryElement);
    stripeCardCvc = elements.getElement(CardCvcElement);

    if (!stripeCardNumber) {
      window.alert('errInitStripe');
      return;
    }
    // await UserService.removeAllPaymentMethod();
    setLoading(true);
    stripe.createToken(stripeCardNumber).then((response) => {
      const { error, token: stripeToken } = response;
      if (error) {
        window.alert(error.message);
      }
      if (stripeToken) {
        console.log('stripToken', stripeToken);

        const loggedIn = document.querySelector('.logged-in-user');
        // const login = document.querySelector('.login');
        if (!loggedIn) {
          GiftCardsService.purchaseGiftCard({
            fromName: yourName,
            fromEmail: yourEmail,
            toName: props.detailData.customerName,
            toEmail: props.detailData.email,
            amount: props.detailData.giftTotal,
            message: props.detailData.message,
            token: stripeToken.id,
          })
            .then((thisRes) => {
              props.onHandleSaveData({
                yourName,
                yourEmail,
                cardNumber,
                cardCVC,
                cardExpireDate,
              });
              props.detailData.giftCode = thisRes.data.giftCard.code;
              history.push(
                `/gift-cards/gift-cards-selector/final?name=${props.detailData.customerName}`,
              );
            })
            .catch((thisErr) => {
              const {
                response: {
                  data: {
                    error: { message },
                  },
                },
              } = thisErr;
              window.alert(message);
            })
            .then(() => {
              setLoading(false);
            });
        } else {
          console.log('111');
          // console.log(getToken());
          UserService.addPaymentMethod(stripeToken.id)
            .then((res) => {
              setPaymentMethodId(res.data.payment_method._id);
              GiftCardsService.purchaseGiftCard({
                toName: props.detailData.customerName,
                toEmail: props.detailData.email,
                amount: props.detailData.giftTotal,
                message: props.detailData.message,
                payment_method_id: res.data.payment_method._id,
              })
                .then((thisRes) => {
                  props.onHandleSaveData({
                    yourName,
                    yourEmail,
                    cardNumber,
                    cardCVC,
                    cardExpireDate,
                  });
                  props.detailData.giftCode = thisRes.data.giftCard.code;
                  history.push(
                    `/gift-cards/gift-cards-selector/final?name=${props.detailData.customerName}`,
                  );
                })
                .catch((thisErr) => {
                  const {
                    response: {
                      data: {
                        error: { message },
                      },
                    },
                  } = thisErr;
                  window.alert(message);
                })
                .then(() => {
                  setLoading(false);
                });
            })
            .catch((addCCError) => {
              // window.alert(`There is an error, please try again later. ${addCCError}`);
              setLoading(false);
            });
          // window.alert('onCheckout');
          console.log('logged in');
        }

        // if (cardNumber) cardNumber.clear();
        // if (cardExpiry) cardExpiry.clear();
        // if (cardCvc) cardCvc.clear();
        // UserService.addPaymentMethod(stripeToken.id)
        // 	.then(() => {
        // 		fetchPaymentInfo();
        // 	});
      }
    });
  };

  const history = useHistory();

  const [cardNumber, setCardNumber] = React.useState(props.data.cardNumber);
  const [cardCVC, setCardCVC] = React.useState(props.data.cardCVC);
  const [cardExpireDate, setCardExpireDate] = React.useState(
    props.data.cardExpireDate,
  );

  const handleSaveDataAndNavigateFinalPage = (event: any) => {
    event.preventDefault();
    /* if (yourName == '' || yourEmail == '' || cardNumber == '' || cardCVC == '' || cardExpireDate == '') {
			alert('Please Insert All Fields!');
			return;
		}
		props.onHandleSaveData({
			yourName, yourEmail, cardNumber, cardCVC, cardExpireDate,
		}); */

    onSaveCC();
    // history.push('/gift-cards/gift-cards-selector/final');
  };

  // Back to top
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {loading ? <LoadingScreen /> : ''}
      <div className="gift-form-top">
        <div className="container-fluid">
          <br />
          <ul className="list-unstyled multi-steps">
            <li onClick={() => history.push('/')} />
            <li className="is-active" />
            <li onClick={() => history.push('/final')} />
          </ul>
        </div>
        <div className="container">
          <h1 className="title">Billing Info</h1>
          <div className="billing-info-holder">
            <div className="screen-msg">
              <p className="total">
                <span className="screen-msg-label">Gift Total:</span>
                <span>
                  HK$
                  {props.detailData.giftTotal}
                  <a
                    href=""
                    onClick={(event) => {
                      event.preventDefault();
                      history.push('/gift-cards/gift-cards-selector/');
                    }}
                  >
                    Edit
                  </a>
                </span>
              </p>
              <p className="email">
                <span className="screen-msg-label">Email:</span>
                <span>{props.detailData.email}</span>
              </p>
              <p className="message">
                <span className="screen-msg-label">Message:</span>
                <span>{props.detailData.message}</span>
              </p>
            </div>

            <div className="billing-info">
              <form method="post" id="billing-form">
                <h3>Your information</h3>
                <div className="section-1">
                  <div className="name">
                    <label>Your name</label>
                    <input
                      type="text"
                      defaultValue={
                        props.authUser ? props.authUser.memberName : ''
                      }
                      onChange={(event) => setYourName(event.target.value)}
                      // onChange={event => setYourName(event.target.value)}
                      name="to"
                      id="to"
                    />
                  </div>
                  {/* name */}
                  <div className="email">
                    <label>Your Email Address</label>
                    <input
                      type="email"
                      defaultValue={
                        props.authUser ? props.authUser.memberName : ''
                      }
                      onChange={(event) => setYourEmail(event.target.value)}
                      name="email"
                      id="email"
                    />
                  </div>
                  {/*    Email */}
                </div>
                {/* Section 1 */}
                <h3>Payment</h3>
                <div className="section-2">
                  {/* <StripeCheckout
										stripeKey="pk_test_Upnwga96MDna6oVCc04NKTl8"
										token={onToken}
									/> */}
                  <div className="stripe-fields">
                    <div className="stripe-field mod-no">
                      <div className="stripe-field-label">Card NUMBER</div>
                      <div className="stripe-element-container">
                        <CardNumberElement
                          options={{
                            style: stripeElementStyle,
                          }}
                        />
                      </div>
                    </div>

                    <div className="stripe-field mod-cvc">
                      <div className="stripe-field-label">CVC</div>
                      <div className="stripe-element-container">
                        <CardCvcElement
                          options={{
                            style: stripeElementStyle,
                          }}
                        />
                      </div>
                    </div>
                    <div className="stripe-field mod-exp">
                      <div className="stripe-field-label">EXPIRY DATE</div>
                      <div className="stripe-element-container">
                        <CardExpiryElement
                          options={{
                            style: stripeElementStyle,
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="stripe-field-btn">
											<button type="button" onClick={() => onSaveCC()}>[TEST] Generate Card Token</button>
										</div> */}
                  </div>
                  {/* expire-date */}
                </div>
                {/* Section 2 */}
              </form>
              <p className="footer-msg">
                Credits can be used for meal plan purchases only. Credit
                expiration date is 12 months from purchase. By purchasing, you
                agreed to the{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/documents/Gift_Card_Terms_and_Conditions.pdf"
                >
                  Gift Terms
                </a>
              </p>
            </div>
            <button
              className="button"
              type="submit"
              form="billing-form"
              onClick={(event) => handleSaveDataAndNavigateFinalPage(event)}
            >
              Confirm Payment
            </button>
          </div>
        </div>
      </div>
      <GiftCardsInfo />
      {/* container */}
    </>
  );
}

// export default GiftCardsBillingInfo;
const mapStateToProps = (state: RootState) => ({
  authUser: selectAuthUser(state),
  token: selectToken(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GiftCardsBillingInfo);
