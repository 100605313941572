import { ActionType } from 'typesafe-actions';
import * as CartActions from './actions';
import * as AuthActions from '../../auth/duck/actions';
import { UserCart } from '../../../models/CartItem';

export interface State {
  userCart: UserCart | null;
  error?: string;
}

const initialState: State = {
  userCart: null,
};

type CartActions = ActionType<typeof CartActions>;
type AuthActions = ActionType<typeof AuthActions>;

export default function reducer(
  state: State = initialState,
  action: CartActions | AuthActions,
): State {
  switch (action.type) {
    case CartActions.FETCH_CART:
      return {
        ...state,
        error: undefined,
      };
    case CartActions.FETCH_CART_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CartActions.FETCH_CART_FAIL:
      return {
        ...state,
        error: action.payload.error,
      };
    case AuthActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
