import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import arrayMove from 'array-move';
import Swiper from 'swiper';
import { fetchAllGoals } from '../../../../api';
import 'swiper/css/swiper.css';
import './HomeMealPlans.scss';

const HomeMealPlans = () => {
  const { t } = useTranslation();
  const langKey = t('langKey');

  const [goals, setGoals] = useState<any>([]);

  function sepcialGoalsArrangement(rawGoals: any) {
    if (!rawGoals || rawGoals.length < 1) return [];
    const goalsArranged: any = [];

    const arrangements = [
      { brandName: 'NOSH Basics', goalName: 'NOSH Basics' },
      { brandName: 'NOSH', goalName: 'Balanced Diet' },
      { brandName: 'NOSH', goalName: 'Lose Weight' },
      { brandName: 'NOSH Flexi', goalName: 'Flexitarian' },
      { brandName: 'NOSH', goalName: 'Build Muscle' },
      { brandName: 'NOSH Veggie', goalName: 'Vegetarian Balanced Diet' },
      { brandName: 'NOSH Veggie', goalName: 'Get Lean' },
      { brandName: 'NOSH Keto', goalName: 'Keto Diet' },
      { brandName: 'NOSH Veggie', goalName: 'Veggie Lose Weight' },
      { brandName: 'NOSH', goalName: 'Gain Weight' },
      { brandName: 'Comfort Food', goalName: 'Comfort Food' },
    ];

    arrangements.forEach((arrangement: any) => {
      const targetGoal = rawGoals.find(
        (goal: any) => goal.name === arrangement.goalName,
      );
      if (targetGoal) {
        goalsArranged.push(targetGoal);
      }
    });

    const notArranged = rawGoals.filter(
      (rawGoal: any) =>
        !goalsArranged?.find((goal: any) => goal._id === rawGoal._id),
    );

    return [...goalsArranged, ...notArranged];
  }

  useEffect(() => {
    fetchAllGoals().then((res) => {
      setGoals(res.data.goals);
    });
  }, []);

  useEffect(() => {
    if (goals && goals.length) {
      setTimeout(() => {
        initSwiper();
      }, 150);
    }
  }, [goals]);

  const initSwiper = () => {
    const swiper = new Swiper('.home-meal-plans-slider .swiper-container', {
      // pagination: {
      // 	el: '.goal-container .swiper-pagination',
      // 	type: 'bullets',
      // 	clickable: true,
      // },
      navigation: {
        nextEl: '.home-meal-plans-slider .swiper-button-next',
        prevEl: '.home-meal-plans-slider .swiper-button-prev',
      },
      slidesPerView: 1,
      spaceBetween: 20,
      watchOverflow: true,
      centerInsufficientSlides: true,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
    });
  };

  return (
    <section className="home-meal-plans">
      <div className="home-meal-plans-inner">
        <h2 className="home-meal-plans-title">
          {t('layout.featuredMenuPlans')}
        </h2>
        <div className="home-meal-plans-slider">
          <div className="swiper-control">
            <button type="button" className="swiper-button-prev">
              {t('mealPlan.prevSlide')}
            </button>
            <button type="button" className="swiper-button-next">
              {t('mealPlan.nextSlide')}
            </button>
          </div>
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {goals &&
                sepcialGoalsArrangement(goals).map(
                  (item: any, index: number) => {
                    const cuisineName = item?.cuisineId?.name?.replace(
                      /[^a-zA-Z0-9-_]/g,
                      '',
                    );
                    const goalName = item?.name?.replace(/[^a-zA-Z0-9-_]/g, '');
                    return (
                      <div
                        key={`${item.name}-${index + 1}`}
                        className="home-meal-plans-slider-item swiper-slide"
                      >
                        <Link
                          to={`/MealPlans/package/${cuisineName}/${goalName}`}
                        >
                          <div
                            className="home-meal-plans-slider-img"
                            style={{ backgroundImage: `url(${item.imageURL})` }}
                          />
                        </Link>
                        <div className="home-meal-plans-info-helper">
                          {t('mealPlan.moreAboutThe')}
                          <Link
                            to={`/MealPlans/${cuisineName}?goal=${item._id}&about=true`}
                          >
                            <button
                              type="button"
                              className="home-meal-plans-info-helper-link"
                            >
                              {item && item.name}
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  },
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMealPlans;
