/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './GiftCardsInfo.scss';

const GiftCardsInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="gift-cards-info">
      <h2 className="gift-cards-info-title">
        #EATSMARTER WITH NOSH E-GIFT CARDS
      </h2>
      <div className="gift-cards-info-cards">
        <div className="gift-cards-info-card">
          <img
            className="gift-cards-info-card-thumb"
            src="/img/img-gift-card-info-thumb-purpose.png"
            alt=""
          />
          <h3 className="gift-cards-info-card-title">FOOD WITH PURPOSE</h3>
          <p className="gift-cards-info-card-desc">
            With every gift card purchase, we donate one meal to a person in
            need in Hong Kong through our partnership with
            <a
              href="https://feedinghk.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Feeding HK
            </a>
            .
          </p>
        </div>
        <div className="gift-cards-info-card">
          <img
            className="gift-cards-info-card-thumb"
            src="/img/img-gift-card-info-thumb-delicious.png"
            alt=""
          />
          <h3 className="gift-cards-info-card-title">
            HEALTHY DELICIOUS MEALS
          </h3>
          <p className="gift-cards-info-card-desc">
            Macro calculated plans for every goal, made with premium,
            ethically-sourced ingredients like Australian Grass-Fed Beef and
            Hormone-Free Chicken in sustainable packaging.
          </p>
        </div>
        <div className="gift-cards-info-card">
          <img
            className="gift-cards-info-card-thumb"
            src="/img/img-gift-card-info-thumb-delivery.png"
            alt=""
          />
          <h3 className="gift-cards-info-card-title">FRESH DAILY DELIVERY</h3>
          <p className="gift-cards-info-card-desc">
            No cooking. no cleanup. Just sit back and relax with free delivery.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GiftCardsInfo;
