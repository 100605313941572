import React, { useEffect, useState } from 'react';
import './Promo.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from 'ReduxTypes';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import i18n from '../../../i18n/i18n';
import * as AccessService from '../../../api/AccessService';
import { selectToken } from '../../../store/auth/duck/selectors';
import { selectNoshUser } from '../../../store/user/duck/selectors';
import { NoshUser } from '../../../models/NoshUser';

import Layout from '../../../layouts/Layout';

interface PromoProps {
  noshUser: NoshUser | null;
}

const PromoThankYou = ({ noshUser }: PromoProps) => {
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState('');
  const [copy, setCopy] = useState({
    value: '',
    copied: false,
  });

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(noshUser?.email);
    if (noshUser?.email) {
      AccessService.subscribePromo({ email: noshUser.email })
        .then((response) => {
          console.log('response', response);
          setPromoCode(response.data.coupon.code);
        })
        .catch((err) => {
          const {
            response: {
              data: {
                error: { message },
              },
            },
          } = err;
          if (message[i18n.getLang()]) {
            window.alert(message[i18n.getLang()]);
          } else {
            window.alert(message);
          }
        });
    }
  }, [noshUser]);

  return (
    <Layout>
      <div className="promo">
        <div className="promo-left left-2">
          <img className="logo-img" src="/img/promo-5.png" alt="logo" />
          <h2 className="promo-h2 h2-2">{t('promo.code.title1')}</h2>
          <h2 className="promo-h2 h2-2">{t('promo.code.title2')}</h2>
          <div className="code-section">
            <input
              type="text"
              value={promoCode}
              className="promo-code"
              readOnly
            />
            <CopyToClipboard
              text={promoCode}
              onCopy={() => setCopy((prev) => ({ ...prev, copied: true }))}
            >
              <button className="copy" type="button">
                {copy.copied ? (
                  <img alt="link" src="/img/check-mark.png" />
                ) : (
                  <img alt="link" src="/img/copy.png" />
                )}
              </button>
            </CopyToClipboard>
          </div>
          <p className="promo-desc">{t('promo.code.description')}</p>
        </div>
        <img src="/img/promo-3.png" alt="food" className="promo-img" />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectToken(state),
  noshUser: selectNoshUser(state),
});

export default connect(mapStateToProps)(PromoThankYou);
