import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RootState } from 'ReduxTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swiper from 'swiper';
import { fetchAddOns, submitAddOns } from '../../../../api';
import * as CartActions from '../../../../store/cart/duck/actions';
import 'swiper/css/swiper.css';
import './KetoPopup.scss';

type Props = {
  visible: boolean;
  onClosePopup: () => void;
};
const dateFormat = 'DD-MM-YYYY';
const labelFormat = 'MMMM, D, YYYY (ddd)';

export const KetoPopup = ({ visible, onClosePopup }: Props) => {
  const { t } = useTranslation();

  const onClickConfirm = () => {
    console.log('onClickConfirm');
    localStorage.setItem('keto_warning_never_show', 'Y');
    onClosePopup();
  };

  return (
    <>
      <div className={`keto-popup ${!visible ? 'mod-invisible' : ''}`}>
        <div className="keto-popup-body">
          <div className="keto-popup-body-inner">
            <div className="keto-popup-head">
              <div />
              <button
                className="keto-popup-close"
                type="button"
                onClick={() => {
                  onClosePopup();
                }}
              >
                <span className="keto-popup-close-graphic">close</span>
                <span className="keto-popup-close-text">close</span>
              </button>
            </div>
            <div className="keto-popup-content mod-active">
              <p className="keto-popup-desc">
                <strong>Friendly reminder</strong>: A ketogenic diet may not be
                suitable for everyone. If you're unfamiliar with keto, our team
                would be happy to connect you up with a professional
                nutritionist before you begin, to see whether it's the right
                plan for you!
              </p>
              <>
                <div className="keto-popup-btns">
                  <a href="mailto:hello@nosh.hk" className="keto-popup-btn">
                    Get in touch
                  </a>
                  <button
                    type="button"
                    className="keto-popup-btn"
                    onClick={() => onClickConfirm()}
                  >
                    Do not show this again
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  // token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCart: () => dispatch(CartActions.fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KetoPopup);
