/* eslint-disable max-len */
import React from 'react';
import './SpecialDetailsPopup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type Props = {
  extraCharge: number;

  exclusions: [];

  selectedExclusions: any[] | null;

  onExclusionsChange: (newExclusions: any) => void;

  // proteinChanges: [];
  // selectedProteinChange: any;
  // onProteinChange: (newProteinChoice: any) => void;

  visible: boolean;

  onConfirm: () => void;
};

const costTypes = [
  '1-5 days: + HK$50 Per Ingredient',
  '10 & 15 days: + HK$100 Per Ingredient',
  '20 days: + HK$100 Per Ingredient',
];

const SpecialDetailsPopup = ({
  exclusions,
  extraCharge,
  onExclusionsChange,
  onConfirm,
  // proteinChanges, onProteinChange, selectedProteinChange,
  selectedExclusions,
  visible,
}: Props) => {
  const { t } = useTranslation();
  if (!visible) return null;

  return (
    <div className="details-popup-container">
      <div className="details-popup-card">
        <div className="header-container">
          <div className="popup-label bottom-border popup-title">
            {t('mealPlan.specialRequest')}
          </div>

          <button type="button" className="close-button" onClick={onConfirm}>
            <FontAwesomeIcon icon={faTimes} size="3x" />
          </button>
        </div>

        <div className="popup-label bold popup-bottom-margin">
          {t('mealPlan.specialRequestTitle1')}
        </div>
        <div className="popup-list-container">
          {exclusions
            .filter(
              (el: any) =>
                el.amount !== 0 && el.name !== '###breakfast_discount###',
            )
            .map((exclusion: any) => (
              <div className="popup-label-small exclusion-item">
                <input
                  type="checkbox"
                  id={`${exclusion._id}-cb`}
                  onChange={() => onExclusionsChange(exclusion)}
                  checked={
                    selectedExclusions
                      ? selectedExclusions.includes(exclusion._id)
                      : false
                  }
                  className="popup-label-small protein-change-item"
                  value={
                    selectedExclusions &&
                    selectedExclusions.includes(exclusion._id)
                      ? exclusion
                      : 0
                  }
                />
                <label htmlFor={`${exclusion._id}-cb`}>
                  {exclusion.name}
                  {`(+$${exclusion.amount} per day)`}
                </label>
              </div>
            ))}
        </div>
        <div className="popup-label bold row">
          {t('mealPlan.specialRequestTitle2')}
          <div className="no-extra-charge">
            {t('mealPlan.specialRequestNote2')}
          </div>
        </div>
        <div className="popup-list-container">
          {exclusions
            .filter(
              (el: any) =>
                el.amount === 0 && el.name !== '###breakfast_discount###',
            )
            .map((exclusion: any) => (
              <div className="popup-label-small exclusion-item">
                <input
                  type="checkbox"
                  id={`${exclusion._id}-cb`}
                  onChange={() => onExclusionsChange(exclusion)}
                  checked={
                    selectedExclusions
                      ? selectedExclusions.includes(exclusion._id)
                      : false
                  }
                  className="popup-label-small protein-change-item"
                  value={
                    selectedExclusions &&
                    selectedExclusions.includes(exclusion._id)
                      ? exclusion
                      : 0
                  }
                />
                <label htmlFor={`${exclusion._id}-cb`}>
                  {exclusion.name}
                  {/* {`(+$${exclusion.amount} per day)`} */}
                </label>
              </div>
            ))}
        </div>

        {/* <div className="popup-label bold row">
					Protein Change
					<div className="no-extra-charge">No Extra Charge</div>
				</div>

				<div className="popup-list-container">
					{proteinChanges.map((proteinChange) => (
						<div
							className="popup-label-small exclusion-item"
						>
							<input
								type="radio"
								id={`${proteinChange}-cb`}
								onChange={() => onProteinChange(proteinChange)}
								checked={selectedProteinChange === proteinChange}
								className="popup-label-small protein-change-item"
							/>
							<label
								htmlFor={`${proteinChange}-cb`}
							>
								{proteinChange}
							</label>
						</div>
					))}
				</div> */}

        <div className="bottom-border" />
        <p>{t('mealPlan.specialDetailsFooter1')}</p>
        <p>{t('mealPlan.specialDetailsFooter2')}</p>

        <div className="popup-bottom-container">
          <div className="total-extra-charge-container">
            <div className="popup-label">{t('mealPlan.totalExtraCharge')}</div>
            <div className="popup-label bold">{`HK$ ${extraCharge}`}</div>
          </div>

          <button type="button" onClick={onConfirm}>
            {t('confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpecialDetailsPopup;
