import React, { useState } from 'react';
import './QaComponent.scss';

type Props = {
  question: string;
  answer: string;
  img?: string;
  content: any;
};

const QaComponent = ({ question, answer, img, content }: Props) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="q-a-container">
      <button type="button" className="question" onClick={() => setShow(!show)}>
        {question}
      </button>
      {show ? (
        <div className="answers display-linebreak">
          <span dangerouslySetInnerHTML={{ __html: answer }} />
          {img ? <img className="faq-img" src={img} alt="" /> : ''}
          {content?.map((c: any) => {
            return (
              <div style={{ textAlign: 'left' }}>
                <h4>{c.subTitle}</h4>
                <span>{c.answer}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default QaComponent;
