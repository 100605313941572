/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import arrayMove from 'array-move';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import * as Actions from './duck/action';
import { selectBrandsData } from './duck/selector';
import { Brands } from '../../models';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import KetoPopup from './components/KetoPopup/KetoPopup';
import { CustomisedPopUp } from '../../components/PopUp/PopUp';
import { fetchAllGoals } from '../../api';

import Layout from '../../layouts/Layout';
import './Step1.scss';

import { Steps } from '../../components';

interface Props {
  brands: Brands | null;
  fetchBrands: () => void;
}

const Step1 = ({ fetchBrands, brands }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [priceList, setPriceList] = useState<any>(null);
  const [goals, setGoals] = useState<any>([]);
  const [ketoUrl, setKetoUrl] = useState<string>('');
  const [ketoPopupOpen, setKetoPopupOpen] = useState<boolean>(false);
  const [basicUrl, setBasicUrl] = useState<string>('');
  const [basicPopupOpen, setBasicPopupOpen] = useState<boolean>(false);

  const checkKeto = (checkString: string) =>
    checkString && checkString.toLowerCase() === 'nosh keto';
  const checkFlexi = (checkString: string) =>
    checkString &&
    (checkString.toLowerCase() === 'flexi' ||
      checkString.toLowerCase() === 'nosh flexi' ||
      checkString.toLowerCase() === 'coming soon!');
  const isBasics = (id: string) => id == '65aa38edff987703b13642d1';

  const sepcialBrandArrangement = (rawData: any) => {
    const targetData = rawData;
    const flexiIndex = _.findIndex(rawData, (brand: any) =>
      checkFlexi(brand?.name?.toLowerCase()),
    );
    const ketoIndex = _.findIndex(rawData, (brand: any) =>
      checkKeto(brand?.name?.toLowerCase()),
    );
    if (flexiIndex > -1) {
      const flexiArrangedData = arrayMove(targetData, flexiIndex, 2);
      if (ketoIndex > -1) {
        // Move "Nosh Keto" to 3rd
        const ketoInFlexi = _.findIndex(flexiArrangedData, (brand: any) =>
          checkKeto(brand?.name?.toLowerCase()),
        );
        return arrayMove(flexiArrangedData, ketoInFlexi, 3);
      }
      return flexiArrangedData;
    }
    if (ketoIndex > -1) {
      // Move "Nosh Keto" to 3rd
      return arrayMove(targetData, ketoIndex, 2);
    }
    return targetData;
  };

  useEffect(() => {
    fetchAllGoals().then((res) => {
      setGoals(res.data.goals);
    });
  }, []);

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const getMinPriceFromList = (list: any) => {
    if (list) {
      let minValue: any;
      Object.entries(list).forEach(([day, value]: any) => {
        const thisVal = parseInt(value, 10) / parseInt(day, 10);
        if (minValue) {
          if (thisVal < minValue) minValue = thisVal;
        } else {
          minValue = thisVal;
        }
      });
      return minValue;
    }
    return 0;
  };

  useEffect(() => {
    const thisPriceList: any = {};
    if (goals && goals.length) {
      goals.forEach((goal: any) => {
        // thisPriceList
        let min_sum: any;
        const min_price_breakfast = getMinPriceFromList(goal.price_breakfast);
        const min_price_lunch = getMinPriceFromList(goal.price_lunch);
        const min_price_dinner = getMinPriceFromList(goal.price_dinner);
        min_sum = Math.min(
          min_price_breakfast + min_price_lunch,
          min_price_breakfast + min_price_dinner,
          min_price_lunch + min_price_dinner,
        );
        if (
          goal.name == 'Keto Life' ||
          goal.name == 'Lean Life' ||
          goal.name == 'Slim Life'
        ) {
          min_sum =
            min_price_breakfast + min_price_lunch + min_price_dinner + 32.5;
          // min_sum = 315;
        }
        if (goal._id == '65aa458eff987703b13642fc') {
          min_sum = min_price_breakfast + min_price_lunch + min_price_dinner;
        }
        thisPriceList[goal.cuisineId._id] = thisPriceList[goal.cuisineId._id]
          ? Math.min(thisPriceList[goal.cuisineId._id], min_sum)
          : min_sum;
        if (goal.cuisineId._id == '651fd8315247f3495648cd39') {
          thisPriceList[goal.cuisineId._id] = 320;
        }
      });
    }
    setPriceList(thisPriceList);
  }, [goals]);

  if (!brands) {
    return null;
  }

  return (
    <Layout>
      <KetoPopup
        visible={ketoPopupOpen}
        onClosePopup={() => {
          setKetoPopupOpen(false);
          history.push(ketoUrl || '/MealPlans/NOSHKeto');
        }}
      />
      <CustomisedPopUp
        visible={basicPopupOpen}
        onClosePopup={() => {
          setKetoPopupOpen(false);
          history.push(ketoUrl || '/MealPlans/NOSHBasics');
        }}
      />
      <main className="steps-container">
        <Steps step={1} links={['', '', '']} />
        <h1 className="a11y-hidden">Healthy Meal Plans</h1>
        <Helmet>
          <title>Best healthy Meal Plan Delivery Service | NOSH</title>
          <meta
            name="description"
            content="Looking for the best healthy meal plan delivery service in Hong Kong? NOSH is your answer. Get premium quality meals delivered to your home or office."
          />
        </Helmet>
        {/* <h1 className="step1-brand-title">{t('mealPlan.step1Title')}</h1> */}
        {!brands ? <LoadingScreen /> : ''}
        <div className="step1-brand-container">
          {sepcialBrandArrangement(brands).map((x: any) => {
            const langKey = t('langKey2');
            const cusineName = x.name;
            const cusineImg = x.imageURL;
            const cusineLogo = x.logoURL;
            const cuisineDesc =
              typeof x.description === 'object'
                ? x.description[langKey]
                : x.description;
            const cuisineMinPrice = priceList
              ? Math.round(priceList[x._id] * 10) / 10 || 0
              : 0;
            return (
              <div key={x._id} className="step1-brand">
                <div className="step1-brand-meal-img">
                  <div className="step1-brand-logo">
                    <div
                      className="step1-brand-logo-src"
                      style={{ backgroundImage: `url(${cusineLogo})` }}
                    />
                  </div>
                  <div
                    className="step1-brand-meal-img-src"
                    style={{ backgroundImage: `url(${cusineImg})` }}
                  />
                </div>
                <div className="step1-brand-head">
                  <div className="step1-brand-name">{cusineName}</div>
                  <div className="step1-brand-price">
                    {cuisineMinPrice && x._id != '65aa38edff987703b13642d1'
                      ? `From $${cuisineMinPrice}/ Day`
                      : `From $85/Main`}
                  </div>
                </div>
                <div className="step1-brand-content">
                  <p dangerouslySetInnerHTML={{ __html: cuisineDesc }} />
                </div>
                {checkKeto(cusineName) ? (
                  <button
                    type="button"
                    className="step1-brand-btn"
                    onClick={() => {
                      const thisKetoUrl = `/MealPlans/${cusineName.replace(
                        /[^a-zA-Z0-9-_]/g,
                        '',
                      )}`;
                      const notShowKeto = localStorage.getItem(
                        'keto_warning_never_show',
                      );
                      if (notShowKeto) {
                        history.push(thisKetoUrl);
                      } else {
                        setKetoUrl(thisKetoUrl);
                        setKetoPopupOpen(true);
                      }
                    }}
                  >
                    {t('mealPlan.select')}
                  </button>
                ) : null}
                {isBasics(x._id.toString()) ? (
                  <button
                    type="button"
                    className="step1-brand-btn"
                    onClick={() => {
                      const thisBasicsUrl = `/MealPlans/${cusineName.replace(
                        /[^a-zA-Z0-9-_]/g,
                        '',
                      )}`;
                      const notShowBasics =
                        localStorage.getItem('hideBasicsPopUp');
                      if (notShowBasics) {
                        history.push(thisBasicsUrl);
                      } else {
                        setBasicUrl(thisBasicsUrl);
                        setBasicPopupOpen(true);
                      }
                    }}
                  >
                    {t('mealPlan.select')}
                  </button>
                ) : null}
                {!isBasics(x._id.toString()) && !checkKeto(cusineName) ? (
                  <Link
                    className="step1-brand-btn"
                    to={`/MealPlans/${cusineName.replace(
                      /[^a-zA-Z0-9-_]/g,
                      '',
                    )}`}
                  >
                    {t('mealPlan.select')}
                  </Link>
                ) : null}
              </div>
            );
          })}
        </div>
      </main>
    </Layout>
  );
};
const mapStateToProps = (state: RootState) => ({
  brands: selectBrandsData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchBrands: () => dispatch(Actions.fetchBrands()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
