import axios from './axios';

export const getCart = () => axios.get('v1/cart');

export const addItem = (data: any) => axios.post('v1/cart/add_item', data);

export const clearCart = () => axios.post('v1/cart/_clear');

export const removeItem = (index: number) =>
  axios.post('v1/cart/_clear_item', { index });
