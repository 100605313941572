import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppConfig from '../../config/AppConfig';

declare global {
  interface Window {
    ga: any;
    gtag: any;
  }
}

const GoogleAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    if (!window.gtag) {
      return;
    }
    // console.log('AppConfig.GA_ID', AppConfig.GA_ID);
    window.gtag('config', AppConfig.GA_ID, {
      page_title: document.title,
      page_path: location.pathname,
    });
  }, [location]);
  return <></>;
};

export default GoogleAnalytics;
