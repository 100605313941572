import React, { useEffect, useState } from 'react';
import './PlanDetailsPopup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Swiper from 'swiper';
import moment from 'moment';
import { Goal } from '../../../../models';
import { fetchGoalAndMeals } from '../../../../api';
import 'swiper/css/swiper.css';
import './PlanMenuPreview.scss';

type Props = {
  goal: Goal | null;
};

const dateFormat = 'DD-MM-YYYY';

const PlanMenuPreview = ({ goal }: Props) => {
  const { t } = useTranslation();
  const langKey = t('langKey');
  const [mealList, setMealList] = useState<Array<object> | null>([]);
  const [currentMeal, setCurrentMeal] = useState<any>(null);

  useEffect(() => {
    if (!goal) return;
    const goalId = goal._id;
    const today = moment();
    fetchGoalAndMeals(
      goalId,
      `${today.add(1, 'd').format(dateFormat)}&end_date=${today.add(7, 'd').format(dateFormat)}`,
    ).then((res) => {
      setMealList(res.data.meals);
    });
  }, [goal]);
  useEffect(() => {
    console.log('mealList', mealList);
    if (!mealList) return;
    setCurrentMeal(mealList[0] || null);
    setTimeout(() => {
      const thisSwiper = new Swiper(
        '.plan-menu-preview-slider .swiper-container',
        {
          pagination: {
            el: '.plan-menu-preview-slider .swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.plan-menu-preview-slider .swiper-button-next',
            prevEl: '.plan-menu-preview-slider .swiper-button-prev',
          },
          slidesPerView: 1,
          spaceBetween: 30,
          watchOverflow: true,
          centerInsufficientSlides: true,
          on: {
            slideChange() {
              // console.log('slide changed', thisSwiper.activeIndex);
              const x = mealList[thisSwiper.activeIndex];
              setCurrentMeal(x || null);
            },
          },
        },
      );
    }, 500);
  }, [mealList]);

  if (!goal) return null;

  if (!mealList || mealList.length < 1) return null;

  return (
    <div className="plan-menu-preview">
      <h2 className="plan-menu-preview-title">{t('mealPlan.menuPreview')}</h2>
      <div className="plan-menu-preview-slider">
        <div className="swiper-control">
          <button type="button" className="swiper-button-prev">
            {t('mealPlan.prevSlide')}
          </button>
          <div className="plan-menu-preview-label">
            <div className="plan-menu-preview-date">
              {currentMeal &&
                moment(currentMeal.date).format('dddd MMMM, D, YYYY')}
            </div>
            <div className="plan-menu-preview-type">
              {currentMeal && t(currentMeal.mealType)}
            </div>
          </div>
          <button type="button" className="swiper-button-next">
            {t('mealPlan.nextSlide')}
          </button>
        </div>
        <div className="swiper-body">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {mealList &&
                mealList.map((x: any) => (
                  <div className="plan-menu-preview-item swiper-slide">
                    <div className="plan-menu-preview-box">
                      {x.dishId.image_url ? (
                        <div className="plan-menu-preview-img">
                          <div className="plan-menu-preview-thumb">
                            <div
                              className="plan-menu-preview-thumb-src"
                              style={{
                                backgroundImage: `url(${x.dishId.image_url})`,
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="plan-menu-preview-txt">
                        <h3 className=".plan-menu-preview-name">
                          {x.dishId[`name_${langKey}`]}
                        </h3>
                        <p className=".plan-menu-preview-calories">
                          {x.dishId.total_calories} {t('mealPlan.calories')}
                          {', '}
                          {x.dishId.total_protein} {t('mealPlan.protein')}
                          {', '}
                          {x.dishId.total_carb} {t('mealPlan.carbs')}
                          {', '}
                          {x.dishId.total_fat} {t('mealPlan.fats')}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanMenuPreview;
