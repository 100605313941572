import React, { useState, useEffect } from 'react';
import './CutOff.scss';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import { fetchCutOffTime } from '../../api';

type RenderProps = {
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  completed: any;
};

export const CutOff = () => {
  const { t } = useTranslation();
  const [countDownTime, setCountDownTime] = useState<any>('');

  useEffect(() => {
    fetchCutOffTime().then((res) => {
      if (res.data.cutoff) {
        setCountDownTime(res.data.cutoff);
      }
    });
  }, []);

  // Random component
  const Completionist = () => (
    <h2 className="section-title">
      {t('mealPlan.orderCutOff')}
      <br />
      {t('mealPlan.thankYou')}
    </h2>
  );

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: RenderProps) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    }
    // Render a countdown
    return (
      <>
        <div className="section-title countdown-title hide-mobile">
          {t('mealPlan.orderCutOffIn')}
        </div>
        <div className="countdown">
          <div className="countdown-item hide-mobile">
            <span>{t('days')}</span>
            <span className="time">{days}</span>
          </div>
          <div className="countdown-item hide-mobile">
            <span>{t('hours')}</span>
            <span className="time">{hours}</span>
          </div>
          <div className="countdown-item hide-mobile">
            <span>{t('mins')}</span>
            <span className="time">{minutes}</span>
          </div>
          <div className="countdown-item hide-mobile mod-last">
            <span>{t('secs')}</span>
            <span className="time">{seconds}</span>
          </div>
          <div className="countdown-item mod-time">
            <span>{t('mealPlan.orderCutOffIn')}</span>
          </div>
          <div className="countdown-item mod-time">
            <span className="time">{days}</span>
            <span>{t('days')}</span>
          </div>
          <div className="countdown-item mod-time">
            <span className="time">
              {hours}
              <span className="time-sp">:</span>
              {minutes}
              <span className="time-sp">:</span>
              {seconds}
            </span>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {countDownTime ? (
        <Countdown date={countDownTime} renderer={renderer} />
      ) : (
        ''
      )}
    </>
  );
};

export default CutOff;
