import { action } from 'typesafe-actions';
import { Brands } from '../../../models';

export const FETCH_BRANDS = 'brands/FETCH_BRANDS';
export const FETCH_BRANDS_SUCCESS = 'brands/FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_FAIL = 'brands/FETCH_BRANDS_FAIL';

export const fetchBrands = () => action(FETCH_BRANDS);
export const fetchBrandsSuccess = (data: Brands) =>
  action(FETCH_BRANDS_SUCCESS, { data });
export const fetchBrandsFail = (error: string) =>
  action(FETCH_BRANDS_FAIL, { error });
