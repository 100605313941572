import React, { useState, useEffect } from 'react';
import { RootState } from 'ReduxTypes';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'swiper/css/swiper.css';
import './FirstOrderPopup.scss';
import { submitFirstOrderLead } from '../../../../api';

const channels = [
  {
    _id: 'channel1',
    name: 'NOSH social media (Facebook/ Instagram)',
  },
  {
    _id: 'channel7',
    name: 'Family/ friend referral',
  },
  {
    _id: 'channel8',
    name: 'Online search (eg. Google/ Yahoo)',
  },
  {
    _id: 'channel2',
    name: 'Influencer referral',
    input: true,
  },
  {
    _id: 'channel3',
    name: 'Our brand partners',
    input: true,
  },
  {
    _id: 'channel4',
    name: 'Gym referrals',
    input: true,
  },
  {
    _id: 'channel5',
    name: 'Lifestyle websites/ magazines',
    input: true,
  },
  {
    _id: 'channel6',
    name: 'Others',
    input: true,
  },
];

type Props = {
  visible: boolean;
  onClosePopup: () => void;
};

export const FirstOrderPopup = ({ visible, onClosePopup }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [step, setStep] = useState<number>(0);

  const [selectedChannels, setSelectedChannels] = useState<any>(null);
  const [channelSpecify, setChannelSpecify] = useState<any>({});

  const onChannelChange = (id: string) => {
    setSelectedChannels(id);
  };
  const updateChannelSpecify = (key: string, text: string) => {
    setChannelSpecify({
      ...channelSpecify,
      [key]: text,
    });
  };

  const onSubmit = () => {
    const submitChoiceText = channels.filter(
      (el: any) => el._id === selectedChannels,
    )[0].name;
    const submitSpecifyText = channelSpecify[selectedChannels]
      ? ` - ${channelSpecify[selectedChannels]}`
      : '';
    const submitValue = `${submitChoiceText}${submitSpecifyText}`;
    // console.log('submitChoiceText', submitChoiceText);
    // console.log('submitSpecifyText', submitSpecifyText);
    // console.log('submitValue', submitValue);

    submitFirstOrderLead(submitValue)
      .then((res: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('firstOrder');
        history.replace({
          search: queryParams.toString(),
        });
        onClosePopup();
      })
      .catch((err: any) => {
        let errMSg = '';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.error) {
              errMSg = err.response.data.error.message;
            }
          }
        }
        window.alert(`There is an error submitting the information: ${errMSg}`);
      });
  };

  return (
    <>
      <div className={`first-order-popup ${!visible ? 'mod-invisible' : ''}`}>
        <div className="first-order-popup-body">
          <div className="first-order-popup-body-inner">
            <div className="first-order-popup-head">
              <div />
              <button
                className="first-order-popup-close"
                type="button"
                onClick={() => {
                  onClosePopup();
                }}
              >
                <span className="first-order-popup-close-graphic">close</span>
                <span className="first-order-popup-close-text">close</span>
              </button>
            </div>
            <div
              className={`first-order-popup-content ${step === 0 ? 'mod-active' : ''}`}
            >
              <h2 className="first-order-popup-title">
                Thank you for ordering with us. <br />
                <br />
                Please let us know where you first learned about NOSH!
              </h2>
              <div className="first-order-popup-scroller">
                {channels.map((item: any) => (
                  <div
                    className={`popup-label-small first-order-channel-item ${selectedChannels === item._id ? 'is-active' : ''}`}
                  >
                    <div className="first-order-channel-checkbox">
                      <input
                        type="checkbox"
                        id={`${item._id}-cb`}
                        onChange={() => onChannelChange(item._id)}
                        checked={selectedChannels === item._id}
                        className="popup-label-small protein-change-item"
                      />
                      <label htmlFor={`${item._id}-cb`}>{item.name}</label>
                    </div>
                    {item.input ? (
                      <div className="first-order-popup-input">
                        <div className="first-order-popup-label">
                          Please specify:
                        </div>
                        <input
                          value={channelSpecify[item._id]}
                          onChange={(e: any) =>
                            updateChannelSpecify(item._id, e.target.value)
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="first-order-popup-btns">
                <button
                  type="button"
                  className="first-order-popup-btn"
                  disabled={!selectedChannels}
                  onClick={() => onSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  // token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // fetchCart: () => dispatch(CartActions.fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirstOrderPopup);
