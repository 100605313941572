/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import { RootState } from 'ReduxTypes';
import { Dispatch } from 'redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import moment from 'moment';
import GiftCardsInfo from './GiftCardsInfo';
import Layout from '../../../layouts/Layout';
import './GiftCardRedeem.scss';
import PopupModal from '../../../components/Header/PopupModal';
import AccessService from '../../../api/AccessService';
import UserService from '../../../api/UserService';
import { setToken } from '../../../api/axios';
import {
  selectToken,
  selectAuthUser,
} from '../../../store/auth/duck/selectors';
import GiftCardRedeemService from '../../../api/GiftCardRedeemService';
import GiftCardsService from '../../../api/GiftCardsService';
import { AuthUser } from '../../../models/AuthUser';
import GiftCards from '../GiftCards';
import i18n from '../../../i18n/i18n';

interface Props {
  authUser: AuthUser | null;
  token: string | null;
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element: any) {
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    ),
  );
}

function GiftCardsRedeem({ authUser, token }: Props) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoggedIn, setIsloggedIn] = useState<boolean>(false);
  const [redeemSuccess, setRedeemSuccess] = useState<boolean>(false);
  const [loginForm, setLoginForm] = useState<{
    username: string;
    password: string;
  }>({ username: '', password: '' });
  const [giftCode, setGiftCode] = useState<string>('');
  const [giftCards, setGiftCards] = useState<any>(null);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [subscribeNewsletter, setSubscribeNewsLetter] = useState<boolean>(true);

  const redeemGift = (event: any) => {
    event.preventDefault();
    console.log(authUser);
    if (authUser) {
      GiftCardRedeemService.redeemGiftCard({
        code: giftCode,
        user: authUser,
      })
        .then((response: any) => {
          console.log('GiftCardsService loggedin', response);
          setRedeemSuccess(true);
          window.scrollTo(0, 0);
        })
        .catch((error: any) => {
          const {
            response: {
              data: {
                error: { message },
              },
            },
          } = error;
          window.alert(message);
        });
    } else if (
      email &&
      name &&
      phoneNumber &&
      password &&
      confirmPassword &&
      giftCode
    ) {
      AccessService.signup(
        name,
        name,
        'M',
        email,
        password,
        Number(phoneNumber),
      )
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            const {
              data: { token },
            } = response;
            setToken(token);
            if (subscribeNewsletter) {
              console.log('inside subscribe newsletter');

              // Timeout so BE can properly save the new user
              setTimeout(() => {
                console.log('inside subscribe newsletter timeout');
                UserService.subscribeToNewsletter(name);
              }, 500);
            }

            GiftCardRedeemService.redeemGiftCard({
              code: giftCode,
            })
              .then((response: any) => {
                console.log('GiftCardsService loggedin', response);
                setIsloggedIn(true);
                setRedeemSuccess(true);
                window.scrollTo(0, 0);
              })
              .catch((error: any) => {
                console.log(error);
                const {
                  response: {
                    data: {
                      error: { message },
                    },
                  },
                } = error;
                window.alert(message);
              });

            // setIsloggedIn(true);
            // login(email, password);
          } else {
            console.log('error signup ');
            // window.alert(t('error.errSignUp'));
          }
        })
        .catch((err) => {
          console.log(`error ${err}`, err.response);
          const {
            response: {
              data: {
                error: { message },
              },
            },
          } = err;
          if (message[i18n.getLang()]) {
            window.alert(message[i18n.getLang()]);
          } else {
            window.alert(message);
          }
        });
    } else {
      alert('Please Insert All Fields!');
    }
  };

  const onModalHide = () => setShowModal(false);

  const onSignUp = (data: any) => {
    const {
      username,
      name,
      gender,
      email,
      password,
      phone,
      subscribeNewsletter,
      // newsletter,
    } = data;

    AccessService.signup(username, name, gender, email, password, Number(phone))
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (subscribeNewsletter) {
            const {
              data: { token },
            } = response;
            setToken(token);
            // Timeout so BE can properly save the new user
            setTimeout(() => {
              UserService.subscribeToNewsletter(name);
            }, 500);
          }
          // login(email, password);
        } else {
          // window.alert(t('error.errSignUp'));
        }
      })
      .catch((err) => {
        // const { response: { data: { error: { message } } } } = err;
        // if (message[i18n.getLang()]) {
        // 	window.alert(message[i18n.getLang()]);
        // } else {
        // 	window.alert(message);
        // }
      });
  };
  const onLogin = () => {
    const { username, password } = loginForm;
    if (username.length === 0 || password.length === 0) {
      window.alert(t('error.errValidUserAndPass'));
    }
  };

  const onSigninFormChanged = (e: any) => {
    const {
      target: { id, value },
    } = e;
    setLoginForm({
      ...loginForm,
      [id.toLowerCase().replace('-input', '')]: value,
    });
  };

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  // 	GiftCardsService.getGiftCard()
  // 		.then((response: any) => {
  // 			console.log('getGiftCard', response);
  // 			setGiftCards(response.data.giftCards);
  // 		})
  // 		.catch((error: any) => {
  // 			console.log(error);
  // 		});
  // }, []);

  // useEffect(() => {
  // 	if (!giftCode || !giftCards) return;
  // 	console.log('giftCode, giftCards', giftCode, giftCards);
  // }, [giftCode, giftCards]);

  return (
    <Layout>
      <PopupModal
        visible={showModal}
        onModalClose={onModalHide}
        onLogin={onLogin}
        onSignUp={onSignUp}
        onSignInFormChanged={onSigninFormChanged}
      />
      <div className={`gift-redeem ${redeemSuccess ? 'mod-success' : ''}`}>
        <div className="gift-cards-top">
          <div className="gift-cards-top-content">
            <h1 className="gift-cards-title">Redeem Your Gift</h1>
            <div className="gift-cards-img-desc">
              <div className="gift-cards-tags">
                <div className="gift-cards-tag mod-s">NOSH MEAL PLAN</div>
                <div className="gift-cards-tag mod-l">GIFT CARD</div>
                <div className="gift-cards-tag mod-m">#EATSMARTER</div>
              </div>
              {redeemSuccess ? (
                <div className="gift-cards-desc">
                  NOSH Credits are now in your account! You can use them to buy
                  any meal plan you like, just remember to spend it by{' '}
                  {moment().add('years', 1).format('DD-MMMM-YYYY')}
                  <Link to="/MealPlans/1" className="gift-cards-btn">
                    Choose Meal Plan
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {!redeemSuccess ? (
          <div className="gift-redeem-top">
            {authUser ? (
              <div className="user-profile">
                <h2 className="user-profile-title">
                  YOU'RE SPECIAL AND YOU KNOW IT
                </h2>
                <div className="user-profile-card">
                  <div className="user-profile-icon">
                    <img
                      alt="profile"
                      className="user-profile-icon-img"
                      src="./img/User.png"
                    />
                  </div>
                  <div className="user-profile-id">
                    <div className="user-profile-id-container">
                      <p className="bold-text">{t('myAccount.signedInAs')}</p>
                      <p>{authUser ? authUser.memberName : ''}</p>
                      <p>{authUser ? authUser.email : ''}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="signup-form">
                <div className="gift-redeem-intro">
                  <h2 className="gift-redeem-intro-title">
                    IT's TIME TO GET SETUP
                  </h2>
                  <p className="gift-redeem-intro-desc">
                    Create your free account or{' '}
                    <button
                      type="button"
                      className="text-success"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const login = document.querySelector('.login');
                        if (login) {
                          simulateMouseClick(login);
                        }
                      }}
                    >
                      Sign In
                    </button>{' '}
                    to redeem your credits.
                  </p>
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address *"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <br />

                <input
                  type="name"
                  id="name"
                  name="name"
                  placeholder="Name *"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <br />
                <input
                  type="tel"
                  id="phone"
                  name="email"
                  placeholder="Phone Number *"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
                <br />
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password *"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <br />
                <input
                  type="password"
                  id="confirm-password"
                  name="confirm-password"
                  placeholder="Confirm Password *"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
                <br />
                <div className="checkbox-item">
                  <input
                    id="newsletter-subscribe-checkbox"
                    type="checkbox"
                    name="subscribeNewsletter"
                    onChange={(event) =>
                      setSubscribeNewsLetter(event.target.checked)
                    }
                    defaultChecked
                  />
                  <label htmlFor="newsletter-subscribe-checkbox">
                    <span>{t('layout.newsletterSubscribeCheckbox')}</span>
                  </label>
                </div>
              </div>
            )}

            <div className="redeem-form">
              <div className="redeem-form-inner">
                <h3 className="redeem-form-desc">
                  Unwrap your gift by adding your gift code below
                </h3>
                <input
                  className="redeem-form-input"
                  type="text"
                  id="giftCode"
                  name="giftCode"
                  value={giftCode}
                  placeholder="Gift Code"
                  onChange={(event) => setGiftCode(event.target.value)}
                />
                <br />
                <button
                  type="button"
                  className="redeem-form-btn"
                  onClick={(event) => redeemGift(event)}
                >
                  Signup and Redeem
                </button>
                <div className="redeem-form-bottom">
                  <p className="redeem-form-desc">Already Registered?</p>
                  <button
                    type="button"
                    onClick={() => {
                      const login = document.querySelector('.login');
                      if (login) {
                        simulateMouseClick(login);
                      }
                    }}
                    className="text-success"
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    Sign In
                  </button>{' '}
                  to redeem your credits.
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <GiftCardsInfo />
      </div>
    </Layout>
  );
}

// export default GiftCardsRedeem;

const mapStateToProps = (state: RootState) => ({
  authUser: selectAuthUser(state),
  token: selectToken(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardsRedeem);
