import React, { useState, useEffect } from 'react';
import { RootState } from 'ReduxTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as CartActions from '../../store/cart/duck/actions';
import './PopUp.scss';

type PopUpProps = {
  visible: boolean;
  onClosePopup: () => void;
};
const dateFormat = 'DD-MM-YYYY';
const labelFormat = 'MMMM, D, YYYY (ddd)';

export const CustomisedPopUp = ({ visible, onClosePopup }: PopUpProps) => {
  const { t } = useTranslation();
  const onClickConfirm = () => {
    console.log('onClickConfirm');
    localStorage.setItem('hideBasicsPopUp', 'Y');
    onClosePopup();
  };
  return (
    <>
      <div className={`keto-popup ${!visible ? 'mod-invisible' : ''}`}>
        <div className="keto-popup-body">
          <div className="keto-popup-body-inner">
            <div className="keto-popup-head">
              <div />
              <button
                className="keto-popup-close"
                type="button"
                onClick={() => {
                  onClosePopup();
                }}
              >
                <span className="keto-popup-close-graphic">close</span>
                <span className="keto-popup-close-text">close</span>
              </button>
            </div>
            <div className="keto-popup-content mod-active">
              <p className="keto-popup-desc">
                <strong>Reminder: NOSH Basics</strong> is a new budget friendly,
                no-frill healthy meal plan.. Differing from the full-serviced
                NOSH meal plans service, NOSH Basic package does not include
                complimentary order dates amendments, ingredient exclusion
                options and free cancellations features. Any questions? Get in
                touch with our NOSH hotline 23881999 OR email hello@nosh.hk
              </p>
              <>
                <div className="keto-popup-btns">
                  <a href="mailto:hello@nosh.hk" className="keto-popup-btn">
                    Get in touch
                  </a>
                  <button
                    type="button"
                    className="keto-popup-btn"
                    onClick={() => onClickConfirm()}
                  >
                    Do not show this again
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  // token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCart: () => dispatch(CartActions.fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomisedPopUp);
