import { takeLatest, put, call } from 'redux-saga/effects';
import * as UserActions from './actions';
import * as UserService from '../../../api/UserService';
import i18n from '../../../i18n/i18n';

export function* handleFetchUser() {
  try {
    const response = yield call(UserService.getUser);
    const {
      data: { user: noshUser },
    } = response;
    yield put(UserActions.fetchUserSuccess(noshUser));
  } catch (error) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error;
    yield put(
      UserActions.fetchUserFail(
        message[i18n.getLang()] || 'Error occurred fetching user',
      ),
    );
  }
}

export function* watchFetchUser() {
  yield takeLatest(UserActions.FETCH_USER, handleFetchUser);
}

export function* handleFetchPaymentInfo() {
  try {
    const response = yield call(UserService.getPaymentInfo);
    const {
      data: { payment_methods },
    } = response;
    yield put(UserActions.fetchPaymentInfoSuccess(payment_methods));
  } catch (error) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error;
    yield put(
      UserActions.fetchUserFail(
        message[i18n.getLang()] || 'Error occurred fetching payment info',
      ),
    );
  }
}

export function* watchFetchPaymentInfo() {
  yield takeLatest(UserActions.FETCH_PAYMENT_INFO, handleFetchPaymentInfo);
}
