/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import GiftCardsSelector from './components/GiftCardsSelector';
import GiftCardsInfo from './components/GiftCardsInfo';
import Layout from '../../layouts/Layout';
import './GiftCards.scss';
import { text } from '@fortawesome/fontawesome-svg-core';

const GiftCards = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [onGiftCardClicked, setGiftCardClicked] = useState(false);

  const onClick = () => {
    setGiftCardClicked(true);
  };

  const toRedeemPage = () => {
    history.push('/redeem');
  };

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Gift Cards | NOSH</title>
        <meta
          name="description"
          content="Get gift cards for your NOSH meal plans and send them to your friends. For every gift card purchased, we donate to Feeding HK. Let’s make good food accessible to everyone!"
        />
      </Helmet>
      <main>
        <div className="gift-cards">
          {onGiftCardClicked ? (
            history.push('/gift-cards/gift-cards-selector/')
          ) : (
            <div className="gift-cards-top">
              <div className="gift-cards-top-content">
                <h2 className="gift-cards-title">
                  A Healthy Gift That Keeps on Giving
                </h2>
                <button
                  type="button"
                  className="gift-cards-label"
                  onClick={onClick}
                >
                  GIFT NOSH
                </button>
                <button
                  type="button"
                  className="gift-cards-label"
                  onClick={toRedeemPage}
                >
                  REDEEM
                </button>
                <div className="gift-cards-img-desc">
                  <div className="gift-cards-tags">
                    <div className="gift-cards-tag mod-s">NOSH MEAL PLAN</div>
                    <div className="gift-cards-tag mod-l">GIFT CARD</div>
                    <div className="gift-cards-tag mod-m">#EATSMARTER</div>
                  </div>
                  <div className="gift-cards-desc">
                    For every gift card purchased,
                    <br />
                    <a href="https://feedinghk.org"> we donate to Feeding Hk</a>
                    <br />
                    to help those in need eat well for a day
                  </div>
                </div>
              </div>
            </div>
          )}
          <GiftCardsInfo />
          <div className="gift-cards-instructions">
            <div className="gift-cards-instructions-holder">
              <h2 className="gift-cards-instructions-title">
                Redeem instructions
              </h2>
              <p className="gift-cards-instructions-desc">
                Confirm purchase with online payment. We will send an email to
                your receiver who will &nbsp;
                <strong>redeem credits in their NOSH account</strong>
                .
                <br />
                (12 months validity)
              </p>
              <div className="gift-cards-btns">
                <div className="gift-cards-btns">
                  <button
                    type="submit"
                    className="gift-cards-btn"
                    onClick={onClick}
                  >
                    GIFT NOSH
                  </button>
                </div>
              </div>
              <br></br>
              <div className="gift-cards-btns">
                <div className="gift-cards-btns">
                  <button
                    type="submit"
                    className="gift-cards-btn"
                    onClick={toRedeemPage}
                  >
                    REDEEM
                  </button>
                </div>
              </div>

              {/* <div className="gift-cards-btns">
								<button type="submit" className="gift-cards-btn" onClick={onClick}>GIFT NOSH</button>
	</div> */}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default GiftCards;
