import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';

export const selectBrands = (state: RootState) => state.brands;

export const selectBrandsData = createSelector(
  selectBrands,
  (brands) => brands.data,
);

export const selectBrand = createSelector(
  selectBrandsData,
  (_: any, id: string) => id,
  (brands, id) => brands && brands.find((item) => item._id === id),
);
